import { Card, Col, Row, Space, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { BucketApproachEnum, ManuallyEditedEnum, SegmentType } from '.'
const { Title, Text } = Typography

const AnnuityCard = ({
    position,
    initValues,
    handleSetAnnuities,
    handleRemove,
    bucketApproach,
    goalAmount,
    inflationPercentage,
    availableFunds,
    manuallyEdited,
}) => {
    let values = { ...initValues }
    delete values.type

    let calculatedMonthlyIncome = undefined
    if (availableFunds && manuallyEdited !== ManuallyEditedEnum.monthlyIncome) {
        if (values.payoutMethod === 'income_rider') {
            if (
                values.withdrawalRatePercentage &&
                values.deferralYears &&
                values.rateOfReturnPercentage
            ) {
                const availableFundsParsed = parseFloat(availableFunds)
                const withdrawalRatePercentage =
                    parseFloat(values.withdrawalRatePercentage) / 100
                const rateOfReturnPercentage =
                    1 + parseFloat(values.rateOfReturnPercentage) / 100
                const deferralYears = parseInt(values.deferralYears)
                const premiumBonusPercentage =
                    1 + parseFloat(values.premiumBonusPercentage) / 100

                // Algorithm provided in latest spec doc 9/18/23
                const annualIncome =
                    availableFundsParsed *
                    withdrawalRatePercentage *
                    rateOfReturnPercentage ** deferralYears *
                    premiumBonusPercentage

                const monthlyIncome = (annualIncome / 12).toFixed(2)
                if (monthlyIncome !== values.monthlyIncome) {
                    calculatedMonthlyIncome = monthlyIncome
                }
            }
        } else {
            if (
                values.withdrawalRatePercentage &&
                values.deferralYears &&
                values.rollupPercentage
            ) {
                const availableFundsParsed = parseFloat(availableFunds)
                const withdrawalRatePercentage =
                    parseFloat(values.withdrawalRatePercentage) / 100
                const rollupPercentage =
                    1 + parseFloat(values.rollupPercentage) / 100
                const deferralYears = parseInt(values.deferralYears)
                const premiumBonusPercentage =
                    1 + parseFloat(values.premiumBonusPercentage) / 100

                const annualIncome =
                    availableFundsParsed *
                    withdrawalRatePercentage *
                    rollupPercentage ** deferralYears *
                    premiumBonusPercentage

                const monthlyIncome = (annualIncome / 12).toFixed(2)
                if (monthlyIncome !== values.monthlyIncome) {
                    calculatedMonthlyIncome = monthlyIncome
                }
            }
        }
    }

    function calculateTotalNeeded() {
        if (values.payoutMethod === 'income_rider') {
            // Start Value = ((Monthly Income Amount * 12) / Withdrawal Rate) / ((1 + Rollup Rate) ^
            // Deferral Years) / (1 + Bonus %)
            const totalNeeded = parseFloat(
                (parseFloat(
                    values.inflationAdjustedMonthlyIncome
                        ? values.inflationAdjustedMonthlyIncome
                        : values.monthlyIncome
                ) *
                    12) /
                    (parseFloat(values.withdrawalRatePercentage) / 100) /
                    (1 + parseFloat(values.rollupPercentage) / 100) **
                        parseInt(values.deferralYears) /
                    (1 + parseFloat(values.premiumBonusPercentage) / 100)
            )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            return totalNeeded === 'NaN' ? 0 : totalNeeded
        } else if (values.payoutMethod === 'annuitization') {
            //                 Start Value = ((Monthly Income Amount * 12) / Withdrawal Rate) / ((1 + Rate of Return) ^
            // Deferral Years) / (1 + Bonus %)
            const totalNeeded = parseFloat(
                (parseFloat(
                    values.inflationAdjustedMonthlyIncome
                        ? values.inflationAdjustedMonthlyIncome
                        : values.monthlyIncome
                ) *
                    12) /
                    (parseFloat(values.withdrawalRatePercentage) / 100) /
                    (1 + parseFloat(values.rateOfReturnPercentage) / 100) **
                        parseInt(values.deferralYears) /
                    (1 + parseFloat(values.premiumBonusPercentage) / 100)
            )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            return totalNeeded === 'NaN' ? 0 : totalNeeded
        }
    }

    let calculatedinflationAdjustedMonthlyIncome = undefined

    function calculateAnnuityAdjMonthlyIncome() {
        //  income goal * (1+inflation rate)^(start year-2023)
        const inflationAdjusted = (
            parseFloat(goalAmount) *
            (1 +
                parseFloat(inflationPercentage ? inflationPercentage : 0) /
                    100) **
                (parseInt(values.startYear) - 2023)
        ).toFixed(2)

        if (inflationAdjusted !== values.inflationAdjustedMonthlyIncome) {
            // If none or NaN use monthlyIncome instead
            console.log('TRY CALCULATING??? -------inside')
            if (inflationAdjusted === 'NaN') {
                if (values.monthlyIncome) {
                    calculatedinflationAdjustedMonthlyIncome =
                        values.monthlyIncome
                } else {
                    calculatedinflationAdjustedMonthlyIncome =
                        calculatedMonthlyIncome
                }
            } else {
                calculatedinflationAdjustedMonthlyIncome = inflationAdjusted
            }
        }
        console.log('TRY CALCULATING???')
    }

    if (manuallyEdited !== ManuallyEditedEnum.inflationAdjustedMonthlyIncome) {
        calculateAnnuityAdjMonthlyIncome()
    }

    return (
        <>
            <Col span={1}></Col>
            <Col span={10}>
                <Card
                    style={{
                        width: '100%',
                    }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Row>
                            <Col span={20}>
                                <Title level={5}>Annuity</Title>
                            </Col>
                            <Col span={4}>
                                <CloseCircleOutlined
                                    style={{ fontSize: '18px', color: 'red' }}
                                    onClick={() =>
                                        handleRemove(
                                            SegmentType.annuity,
                                            position
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="startYear"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'startYear',
                                                e.target.value
                                            )
                                        }}
                                        value={values.startYear}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Start year
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="deferralYears"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'deferralYears',
                                                e.target.value
                                            )
                                        }}
                                        value={values.deferralYears || ''}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Deferral years
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="number"
                                        name="monthlyIncome"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'monthlyIncome',
                                                e.target.value,
                                                true
                                            )
                                        }}
                                        value={
                                            values.monthlyIncome
                                                ? values.monthlyIncome
                                                : calculatedMonthlyIncome
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Monthly income
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="incomeYears"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'incomeYears',
                                                e.target.value
                                            )
                                        }}
                                        value={values.incomeYears || ''}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Income years
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="inflationAdjustedMonthlyIncome"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'inflationAdjustedMonthlyIncome',
                                                e.target.value,
                                                true
                                            )
                                        }}
                                        value={
                                            values.inflationAdjustedMonthlyIncome
                                                ? values.inflationAdjustedMonthlyIncome
                                                : calculatedinflationAdjustedMonthlyIncome
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Inflation adj. income
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="premiumBonusPercentage"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'premiumBonusPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={
                                            values.premiumBonusPercentage || ''
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Premium bonus %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="rollupPercentage"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'rollupPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={values.rollupPercentage || ''}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Rollup %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="rateOfReturnPercentage"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'rateOfReturnPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={
                                            values.rateOfReturnPercentage || ''
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Rate of return %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="withdrawalRatePercentage"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'withdrawalRatePercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={
                                            values.withdrawalRatePercentage ||
                                            ''
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Withdrawal rate %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <select
                                        name="payoutMethod"
                                        onChange={(e) => {
                                            handleSetAnnuities(
                                                position,
                                                'payoutMethod',
                                                e.target.value
                                            )
                                        }}
                                        value={values.payoutMethod}
                                        placeholder=""
                                        className="material-textfield-input"
                                    >
                                        <option value={'income_rider'}>
                                            Income rider
                                        </option>
                                        <option value={'annuitization'}>
                                            Annuitization
                                        </option>
                                    </select>
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Payout method
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        {/* {bucketApproach === BucketApproachEnum.income_goal && ( */}
                        <>
                            <Row>
                                <Col span={12}>
                                    <Text>Total needed</Text>
                                </Col>
                                <Col
                                    span={12}
                                    style={{
                                        textAlign: 'end',
                                    }}
                                >
                                    <Text>
                                        $
                                        <span className="annuityTotalNeeded">
                                            {calculateTotalNeeded()}
                                        </span>
                                    </Text>
                                </Col>
                            </Row>
                        </>
                        {/* )} */}
                        {bucketApproach ===
                            BucketApproachEnum.available_funds && (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <Text>Available funds</Text>
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'end',
                                        }}
                                    >
                                        <Text className="annuityAvailableFunds">
                                            ${availableFunds}
                                        </Text>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Space>
                </Card>
            </Col>
        </>
    )
}

export default AnnuityCard
