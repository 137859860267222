import { Button, Form, Input } from 'antd'
import React from 'react'
import { connect } from 'react-redux'

class AgentAddForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.handleSubmit(this.props.agencyId, values)
            }
        })
    }

    componentDidUpdate(pProps, pStates) {
        if (this.props.data !== pProps.data && this.props.data !== undefined) {
            console.debug('Data loaded', this.props.data)
            this.props.form.setFieldsValue(this.props.data)
        }
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="First Name" hasFeedback>
                    {getFieldDecorator('firstName', {
                        rules: [
                            { required: true, message: 'Please enter a name' },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Last Name" hasFeedback>
                    {getFieldDecorator('lastName', {
                        rules: [
                            { required: true, message: 'Please enter a name' },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Phone" hasFeedback>
                    {getFieldDecorator('phone', {
                        rules: [
                            {
                                required: false,
                                message: 'Please enter a phone number',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Fax" hasFeedback>
                    {getFieldDecorator('fax', {
                        rules: [
                            { required: false, message: 'Please enter a fax' },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Email" hasFeedback>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                required: true,
                                message: 'Please enter an e-mail',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

// const AgentAddForm = Form.create({ name: 'agent_add_form' })(AgentAdd)
// export default AgentAddForm

const mapStateToProps = (state) => {
    return {
        name: 'agent_add_form',
        loading: state.loading,
        error: state.error,
    }
}

export default connect(mapStateToProps, {})(AgentAddForm)
