import {
    Button,
    Checkbox,
    Col,
    Radio,
    RadioChangeEvent,
    Row,
    Space,
    Typography,
} from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import {
    requestStates,
    requestAgent,
    requestClient,
    requestAgency,
} from '../../../actions'
const { Title, Text } = Typography

const valSchema = yup.object().shape({
    company: yup
        .string()
        .min(3, 'Agency name must be at least 3 characters long.')
        .required('Agency name is required.'),
    state: yup.string().required('State is a required field'),
    email: yup.string().email('Must be a valid email address'),
    phone: yup.string().max(10).required('Phone is required'),
    fax: yup.string().max(10),
    postal: yup.string().min(5).max(5),
})

const advisorValidationSchema = yup.object().shape({
    firstName: yup
        .string()
        .min(3, 'First name must be at least 3 characters long.')
        .required('First name is required.'),
    lastName: yup
        .string()
        .min(3, 'Last name must be at least 3 characters long.')
        .required('Last name is required.'),
    email: yup.string().email('Must be a valid email address'),
    phone: yup.string().max(10).required('Phone is required'),
    fax: yup.string().max(10),
})

const clientValidationSchema = yup.object().shape({
    firstName: yup
        .string()
        .min(3, 'First name must be at least 3 characters long.')
        .required('First name is required.'),
    lastName: yup
        .string()
        .min(3, 'Last name must be at least 3 characters long.')
        .required('Last name is required.'),
    email: yup
        .string()
        .email('Must be a valid email address')
        .required('Email is required'),
    phone: yup.string().max(10).required('Phone is required'),
    gender: yup.number().required('Gender is required'),
    currentAge: yup.string().required('Age is required'),
    address: yup.string().required('Address is required'),
    state: yup.string().required('State is required'),
    postal: yup.string().required('Zip code is required'),
    spouseFirstName: yup.string().optional(),
    spouseLastName: yup.string().optional(),
    spouseGender: yup.number().optional(),
    spouseAge: yup.string().optional(),
    spouseEmail: yup.string().optional(),
    filing_status: yup.number().required('Filing status is required'),
})

// TODO : FIX ANY
const ProfileForm = (props: any) => {
    const {
        states,
        agency,
        agent,
        client,
        error,
        showProfileForm,
        handleProfileFormSubmit,
        cancelShowProfileForm,
        selectedAgency,
    } = props

    if (showProfileForm.for == 'agency') {
        useEffect(() => {
            if (showProfileForm.type == 'edit') {
                props.requestAgency(showProfileForm.id)
            }
        }, [])

        return (
            <Formik
                validationSchema={valSchema}
                initialValues={
                    agency.data && showProfileForm.type == 'edit'
                        ? {
                              company: agency.data.company,
                              address: agency.data.address,
                              address2: agency.data.address2,
                              city: agency.data.city,
                              state: agency.data.state,
                              postal: agency.data.postal,
                              phone: agency.data.phone,
                              fax: agency.data.fax,
                              email: agency.data.email,
                          }
                        : {
                              company: '',
                              address: '',
                              address2: '',
                              city: '',
                              state: '',
                              postal: '',
                              phone: '',
                              fax: '',
                              email: '',
                          }
                }
                onSubmit={(values, actions) => {
                    handleProfileFormSubmit(values)
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => (
                    <Form>
                        {agency.data && showProfileForm.type == 'edit' ? (
                            <Title level={4}>
                                {values.company}{' '}
                                <Text type="secondary">Agency</Text>
                            </Title>
                        ) : (
                            <h3>Add agency</h3>
                        )}
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ display: 'flex' }}
                        >
                            <Row>
                                <Col>
                                    <Text>Agency admin information</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    <Space
                                        direction="vertical"
                                        size="middle"
                                        style={{ display: 'flex' }}
                                    >
                                        <Row>
                                            <Col span={22}>
                                                <div className="material-textfield">
                                                    <input
                                                        placeholder=""
                                                        type="text"
                                                        name="company"
                                                        onChange={handleChange}
                                                        value={values.company}
                                                        className="material-textfield-input"
                                                    />
                                                    <label
                                                        style={{
                                                            fontWeight:
                                                                'normal',
                                                        }}
                                                    >
                                                        Agency name
                                                    </label>
                                                </div>
                                                <div className="profile-form-error">
                                                    {errors.company &&
                                                        touched.company &&
                                                        errors.company}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="address"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.address
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Address 1
                                                        </label>
                                                    </div>
                                                    <div className="profile-form-error">
                                                        {errors.address &&
                                                            touched.address &&
                                                            errors.address}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="address2"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.address2
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Address 2
                                                        </label>
                                                    </div>
                                                    <div className="profile-form-error">
                                                        {errors.address2 &&
                                                            touched.address2 &&
                                                            errors.address2}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <select
                                                            name="state"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            placeholder=""
                                                            className="material-textfield-input"
                                                            value={values.state}
                                                        >
                                                            <option>
                                                                --Select a
                                                                state---
                                                            </option>
                                                            {states &&
                                                                states.data &&
                                                                states.data.map(
                                                                    function (
                                                                        state: {
                                                                            id: string
                                                                            name: string
                                                                        },
                                                                        index: any
                                                                    ) {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    state.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    state.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    }
                                                                )}
                                                        </select>
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            State
                                                        </label>
                                                    </div>
                                                    <div className="profile-form-error">
                                                        {errors.state &&
                                                            touched.state &&
                                                            errors.state}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="postal"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.postal
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Zip code
                                                        </label>
                                                    </div>
                                                    <div className="profile-form-error">
                                                        {errors.postal &&
                                                            touched.postal &&
                                                            errors.postal}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="email"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.email}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Email
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.email &&
                                                            touched.email &&
                                                            errors.email}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="phone"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.phone}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Phone
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.phone &&
                                                            touched.phone &&
                                                            errors.phone}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                    </Space>
                                </Col>
                                {/* <Col span={8}>
                                    <Upload
                                        action="/upload.do"
                                        listType="picture-card"
                                    >
                                        <div>
                                            <PlusOutlined rev={undefined} />
                                            <div style={{ marginTop: 8 }}>
                                                Select Profile Image
                                            </div>
                                        </div>
                                    </Upload>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col span={6}></Col>
                                <Col>
                                    <Button
                                        type="text"
                                        size={'middle'}
                                        onClick={() =>
                                            cancelShowProfileForm({
                                                for: showProfileForm.for,
                                                id: showProfileForm.id,
                                            })
                                        }
                                        style={{ color: '#00BAC9' }}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        size={'middle'}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                )}
            </Formik>
        )
    } else if (showProfileForm.for == 'advisor') {
        useEffect(() => {
            if (showProfileForm.type == 'edit') {
                props.requestAgent(showProfileForm.id)
            }
        }, [])

        return (
            <Formik
                validationSchema={advisorValidationSchema}
                initialValues={
                    agent.data && showProfileForm.type == 'edit'
                        ? {
                              firstName: agent.data.firstName,
                              lastName: agent.data.lastName,
                              phone: agent.data.phone,
                              fax: agent.data.fax,
                              email: agent.data.email,
                          }
                        : {
                              firstName: '',
                              lastName: '',
                              phone: '',
                              fax: '',
                              email: '',
                          }
                }
                onSubmit={(values, actions) => {
                    handleProfileFormSubmit(values)
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => (
                    <Form>
                        <Row align="bottom">
                            <Col span={16}>
                                {agent.data &&
                                showProfileForm.type == 'edit' ? (
                                    <Title level={4}>
                                        {values.firstName} {values.lastName}{' '}
                                        <Text type="secondary">Advisor</Text>
                                    </Title>
                                ) : (
                                    <h3>Add advisor</h3>
                                )}
                            </Col>
                            <Col
                                span={8}
                                style={{ textAlign: 'right', paddingRight: 30 }}
                            >
                                <Text type="secondary">
                                    {selectedAgency.name}
                                </Text>
                            </Col>
                        </Row>

                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ display: 'flex' }}
                        >
                            <Row>
                                <Col>
                                    <Text>Advisor information</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    <Space
                                        direction="vertical"
                                        size="middle"
                                        style={{ display: 'flex' }}
                                    >
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="firstName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.firstName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            First name
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.firstName &&
                                                            touched.firstName &&
                                                            errors.firstName}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="lastName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.lastName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Last name
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.lastName &&
                                                            touched.lastName &&
                                                            errors.lastName}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="email"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.email}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Email
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.email &&
                                                            touched.email &&
                                                            errors.email}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="phone"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.phone}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Phone
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.phone &&
                                                            touched.phone &&
                                                            errors.phone}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="fax"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.fax}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Fax
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.fax &&
                                                            touched.fax &&
                                                            errors.fax}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                    </Space>
                                </Col>
                                {/* <Col span={8}>
                                    <Upload
                                        action="/upload.do"
                                        listType="picture-card"
                                    >
                                        <div>
                                            <PlusOutlined rev={undefined} />
                                            <div style={{ marginTop: 8 }}>
                                                Select Profile Image
                                            </div>
                                        </div>
                                    </Upload>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col span={6}></Col>
                                <Col>
                                    <Button
                                        type="text"
                                        size={'middle'}
                                        onClick={() =>
                                            cancelShowProfileForm({
                                                for: showProfileForm.for,
                                                id: showProfileForm.id,
                                            })
                                        }
                                        style={{ color: '#00BAC9' }}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        size={'middle'}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                )}
            </Formik>
        )
    } else if (showProfileForm.for == 'client') {
        useEffect(() => {
            if (showProfileForm.type == 'edit') {
                props.requestClient(showProfileForm.id)
            }
        }, [])

        return (
            <Formik
                validationSchema={clientValidationSchema}
                initialValues={
                    client.id && showProfileForm.type == 'edit'
                        ? {
                              firstName: client.firstName,
                              lastName: client.lastName,
                              gender: client.gender,
                              currentAge: client.currentAge,
                              address: client.address,
                              address2: client.address2,
                              state: client.state,
                              postal: client.postal,
                              email: client.email,
                              phone: client.phone,
                              filing_status: client.filingStatus,
                              spouseFirstName: client.spouseFirstName,
                              spouseLastName: client.spouseLastName,
                              spouseGender: client.spouseGender
                                  ? client.spouseGender
                                  : undefined,
                              spouseAge: client.spouseAge
                                  ? client.spouseAge
                                  : undefined,
                              spouseEmail: client.spouseEmail,
                              agent: client.selectedAgent.value,

                              retirementAge: client.retirementAge,
                              retirementGoals: client.retirementGoals,
                              socialSecurityMonthlyIncome:
                                  client.socialSecurityMonthlyIncome,
                              socialSecurityAnnualGrowthRate:
                                  client.socialSecurityAnnualGrowthRate
                                      ? client.socialSecurityAnnualGrowthRate
                                      : 0.02,
                              socialSecurityStartAge:
                                  client.socialSecurityStartAge,
                              socialSecurityEndAge: client.socialSecurityEndAge
                                  ? client.socialSecurityEndAge
                                  : 100,
                              socialSecurityDeductFromMonthly:
                                  client.socialSecurityDeductFromMonthly
                                      ? client.socialSecurityDeductFromMonthly
                                      : true,
                              pensionMonthlyIncome: client.pensionMonthlyIncome,
                              pensionAnnualGrowthRate:
                                  client.pensionAnnualGrowthRate,
                              pensionStartAge: client.pensionStartAge,
                              pensionEndAge: client.pensionEndAge
                                  ? client.pensionEndAge
                                  : 100,
                              pensionDeductFromMonthly:
                                  client.pensionDeductFromMonthly
                                      ? client.pensionDeductFromMonthly
                                      : true,
                              employmentMonthlyIncome:
                                  client.employmentMonthlyIncome,
                              employmentAnnualGrowthRate:
                                  client.employmentAnnualGrowthRate
                                      ? client.employmentAnnualGrowthRate
                                      : 0.02,
                              employmentStartAge: client.employmentStartAge
                                  ? client.employmentStartAge
                                  : new Date().getFullYear().toString(),
                              employmentEndAge: client.employmentEndAge,
                              employmentDeductFromMonthly:
                                  client.employmentDeductFromMonthly
                                      ? client.employmentDeductFromMonthly
                                      : true,
                              qualifiedPlansMonthlyIncome:
                                  client.qualifiedPlansMonthlyIncome,
                              qualifiedPlansAnnualGrowthRate:
                                  client.qualifiedPlansAnnualGrowthRate
                                      ? client.qualifiedPlansAnnualGrowthRate
                                      : 0.05,
                              qualifiedPlansStartAge:
                                  client.qualifiedPlansStartAge,
                              qualifiedPlansEndAge: client.qualifiedPlansEndAge
                                  ? client.qualifiedPlansEndAge
                                  : 100,
                              qualifiedPlansDeductFromMonthly:
                                  client.qualifiedPlansDeductFromMonthly
                                      ? client.qualifiedPlansDeductFromMonthly
                                      : true,
                              savingsInvestmentsMonthlyIncome:
                                  client.savingsInvestmentsMonthlyIncome,
                              savingsInvestmentsAnnualGrowthRate:
                                  client.savingsInvestmentsAnnualGrowthRate
                                      ? client.savingsInvestmentsAnnualGrowthRate
                                      : 0.05,
                              savingsInvestmentsStartAge:
                                  client.savingsInvestmentsStartAge,
                              savingsInvestmentsEndAge:
                                  client.savingsInvestmentsEndAge
                                      ? client.savingsInvestmentsEndAge
                                      : 100,
                              savingsInvestmentsDeductFromMonthly:
                                  client.savingsInvestmentsDeductFromMonthly
                                      ? client.savingsInvestmentsDeductFromMonthly
                                      : true,
                              desiredBenefitMonthlyIncome:
                                  client.desiredBenefitMonthlyIncome,
                              desiredBenefitAnnualGrowthRate:
                                  client.desiredBenefitAnnualGrowthRate
                                      ? client.desiredBenefitAnnualGrowthRate
                                      : 0.02,
                              desiredBenefitStartAge:
                                  client.desiredBenefitStartAge,
                              desiredBenefitEndAge: client.desiredBenefitEndAge
                                  ? client.desiredBenefitEndAge
                                  : 100,
                              desiredBenefitDeductFromMonthly:
                                  client.desiredBenefitDeductFromMonthly
                                      ? client.desiredBenefitDeductFromMonthly
                                      : true,
                              federalTaxRateCurrent:
                                  client.federalTaxRateCurrent,
                              federalTaxRateRetirement:
                                  client.federalTaxRateRetirement,
                              stateLocalTaxRateCurrent:
                                  client.stateLocalTaxRateCurrent,
                              stateLocalTaxRateRetirement:
                                  client.stateLocalTaxRateRetirement,
                              capitalGainsTaxRateCurrent:
                                  client.capitalGainsTaxRateCurrent,
                              capitalGainsTaxRateRetirement:
                                  client.capitalGainsTaxRateRetirement,
                          }
                        : {
                              firstName: '',
                              lastName: '',
                              gender: '',
                              currentAge: '',
                              address: '',
                              address2: '',
                              state: '',
                              postal: '',
                              email: '',
                              phone: '',
                              filing_status: '',
                              spouseFirstName: '',
                              spouseLastName: '',
                              spouseGender: undefined,
                              spouseAge: undefined,
                              spouseEmail: '',
                              agent: showProfileForm.advisorId,
                              retirementAge: undefined,
                              retirementGoals: undefined,
                              socialSecurityMonthlyIncome: undefined,
                              socialSecurityAnnualGrowthRate: 0.02,
                              socialSecurityStartAge: undefined,
                              socialSecurityEndAge: 100,
                              socialSecurityDeductFromMonthly: true,
                              pensionMonthlyIncome: undefined,
                              pensionAnnualGrowthRate: 0,
                              pensionStartAge: undefined,
                              pensionEndAge: 100,
                              pensionDeductFromMonthly: true,
                              employmentMonthlyIncome: undefined,
                              employmentAnnualGrowthRate: 0.02,
                              employmentStartAge: new Date()
                                  .getFullYear()
                                  .toString(),
                              employmentEndAge: undefined,
                              employmentDeductFromMonthly: true,
                              qualifiedPlansMonthlyIncome: undefined,
                              qualifiedPlansAnnualGrowthRate: 0.05,
                              qualifiedPlansStartAge: undefined,
                              qualifiedPlansEndAge: 100,
                              qualifiedPlansDeductFromMonthly: true,
                              savingsInvestmentsMonthlyIncome: undefined,
                              savingsInvestmentsAnnualGrowthRate: 0.05,
                              savingsInvestmentsStartAge: undefined,
                              savingsInvestmentsEndAge: 100,
                              savingsInvestmentsDeductFromMonthly: true,
                              desiredBenefitMonthlyIncome: undefined,
                              desiredBenefitAnnualGrowthRate: 0.02,
                              desiredBenefitStartAge: undefined,
                              desiredBenefitEndAge: 100,
                              desiredBenefitDeductFromMonthly: true,
                              federalTaxRateCurrent: undefined,
                              federalTaxRateRetirement: undefined,
                              stateLocalTaxRateCurrent: undefined,
                              stateLocalTaxRateRetirement: undefined,
                              capitalGainsTaxRateCurrent: undefined,
                              capitalGainsTaxRateRetirement: undefined,
                          }
                }
                onSubmit={(values, actions) => {
                    handleProfileFormSubmit(values)
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => (
                    <Form>
                        <Row align="bottom">
                            <Col span={16}>
                                {client.firstName &&
                                showProfileForm.type == 'edit' ? (
                                    <Title level={4}>
                                        {values.firstName} {values.lastName}{' '}
                                        <Text type="secondary">Client</Text>
                                    </Title>
                                ) : (
                                    <h3>Add client</h3>
                                )}
                            </Col>
                            <Col
                                span={8}
                                style={{ textAlign: 'right', paddingRight: 30 }}
                            >
                                <Text type="secondary">
                                    {selectedAgency.name}
                                </Text>
                            </Col>
                        </Row>
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ display: 'flex' }}
                        >
                            <Row>
                                <Col>
                                    <Text>Client and spouse information</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20}>
                                    <Space
                                        direction="vertical"
                                        size="middle"
                                        style={{ display: 'flex' }}
                                    >
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="firstName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.firstName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            First name
                                                        </label>
                                                    </div>
                                                    <div className="profile-form-error">
                                                        {errors.firstName &&
                                                            touched.firstName &&
                                                            errors.firstName}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="lastName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.lastName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Last name
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.lastName &&
                                                            touched.lastName &&
                                                            errors.lastName}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <label>Gender*</label>
                                                    <div role="group">
                                                        <Text>
                                                            <Field
                                                                type="radio"
                                                                name="gender"
                                                                value={1}
                                                                checked={
                                                                    values.gender ==
                                                                    1
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />{' '}
                                                            Male
                                                        </Text>
                                                        {'  '}
                                                        <Text>
                                                            <Field
                                                                type="radio"
                                                                name="gender"
                                                                value={2}
                                                                checked={
                                                                    values.gender ==
                                                                    2
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />{' '}
                                                            Female
                                                        </Text>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.gender &&
                                                            touched.gender &&
                                                            errors.gender}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="currentAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.currentAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.currentAge &&
                                                            touched.currentAge &&
                                                            errors.currentAge}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="address"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.address
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Address 1
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.address &&
                                                            touched.address &&
                                                            errors.address}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="address2"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.address2
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Address 2
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.address2 &&
                                                            touched.address2 &&
                                                            errors.address2}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <select
                                                            name="state"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            placeholder=""
                                                            value={values.state}
                                                            className="material-textfield-input"
                                                        >
                                                            <option>
                                                                --Select a
                                                                state---
                                                            </option>
                                                            {states &&
                                                                states.data &&
                                                                states.data.map(
                                                                    function (
                                                                        state: {
                                                                            id: string
                                                                            name: string
                                                                        },
                                                                        index: any
                                                                    ) {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    state.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    state.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    }
                                                                )}
                                                        </select>
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            State
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.state &&
                                                            touched.state &&
                                                            errors.state}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="postal"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.postal
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Zip code
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.postal &&
                                                            touched.postal &&
                                                            errors.postal}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="email"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.email}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Email
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.email &&
                                                            touched.email &&
                                                            errors.email}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="phone"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.phone}
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Phone
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.phone &&
                                                            touched.phone &&
                                                            errors.phone}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Spouse</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="spouseFirstName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.spouseFirstName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            First name
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.spouseFirstName &&
                                                            touched.spouseFirstName &&
                                                            errors.spouseFirstName}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="spouseLastName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.spouseLastName
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Last name
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.spouseLastName &&
                                                            touched.spouseLastName &&
                                                            errors.spouseLastName}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <label>Gender</label>
                                                    <div role="group">
                                                        <Text>
                                                            <Field
                                                                type="radio"
                                                                name="spouseGender"
                                                                value={1}
                                                                checked={
                                                                    values.spouseGender ==
                                                                    1
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />{' '}
                                                            Male
                                                        </Text>
                                                        {'  '}
                                                        <Text>
                                                            <Field
                                                                type="radio"
                                                                name="spouseGender"
                                                                value={2}
                                                                checked={
                                                                    values.spouseGender ==
                                                                    2
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />{' '}
                                                            Female
                                                        </Text>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.spouseGender &&
                                                            touched.spouseGender &&
                                                            errors.spouseGender}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="spouseAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.spouseAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.spouseAge &&
                                                            touched.spouseAge &&
                                                            errors.spouseAge}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="spouseEmail"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.spouseEmail
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Email
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.spouseEmail &&
                                                            touched.spouseEmail &&
                                                            errors.spouseEmail}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <select
                                                            name="filing_status"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.filing_status
                                                            }
                                                            placeholder=""
                                                            className="material-textfield-input"
                                                        >
                                                            <option>
                                                                --Select a
                                                                filing status---
                                                            </option>
                                                            <option value={1}>
                                                                Single
                                                            </option>
                                                            <option value={2}>
                                                                Married filing
                                                                Jointly
                                                            </option>
                                                            <option value={3}>
                                                                Married filing
                                                                separately
                                                            </option>
                                                            <option value={4}>
                                                                Head of
                                                                Household
                                                            </option>
                                                        </select>
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Filing status
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.filing_status &&
                                                            touched.filing_status &&
                                                            errors.filing_status}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Retirement Information
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Retirement age</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="material-textfield">
                                                    <input
                                                        placeholder=""
                                                        type="text"
                                                        name="retirementAge"
                                                        onChange={handleChange}
                                                        value={
                                                            values.retirementAge
                                                        }
                                                        className="material-textfield-input"
                                                    />
                                                    <label
                                                        style={{
                                                            fontWeight:
                                                                'normal',
                                                        }}
                                                    >
                                                        Age
                                                    </label>
                                                </div>

                                                <div className="profile-form-error">
                                                    {errors.retirementAge &&
                                                        touched.retirementAge &&
                                                        errors.retirementAge}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Retirement goals (select up
                                                    to 3)
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Checkbox.Group
                                                    name="retirementGoals"
                                                    options={[
                                                        'Income Planning',
                                                        'Estate Planning',
                                                        'Legacy for Heirs',
                                                        'Capital Preservation',
                                                        'Tax Mitigation',
                                                        'Aggressive Asset Growth',
                                                    ]}
                                                    defaultValue={
                                                        values.retirementGoals
                                                    }
                                                    onChange={(
                                                        checkedValues: CheckboxValueType[]
                                                    ) => {
                                                        handleChange({
                                                            target: {
                                                                type: 'text',
                                                                name: 'retirementGoals',
                                                                id: 'retirementGoals',
                                                                value: checkedValues.toString(),
                                                            },
                                                        })
                                                    }}
                                                />

                                                <div className="profile-form-error">
                                                    {errors.retirementGoals &&
                                                        touched.retirementGoals &&
                                                        errors.retirementGoals}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Social secruity</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="socialSecurityMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.socialSecurityMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.socialSecurityMonthlyIncome &&
                                                            touched.socialSecurityMonthlyIncome &&
                                                            errors.socialSecurityMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="socialSecurityAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.socialSecurityAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.socialSecurityAnnualGrowthRate &&
                                                            touched.socialSecurityAnnualGrowthRate &&
                                                            errors.socialSecurityAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="socialSecurityStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.socialSecurityStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.socialSecurityStartAge &&
                                                            touched.socialSecurityStartAge &&
                                                            errors.socialSecurityStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="socialSecurityEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.socialSecurityEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.socialSecurityEndAge &&
                                                            touched.socialSecurityEndAge &&
                                                            errors.socialSecurityEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'socialSecurityDeductFromMonthly',
                                                                    id: 'socialSecurityDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.socialSecurityDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.socialSecurityDeductFromMonthly &&
                                                            touched.socialSecurityDeductFromMonthly &&
                                                            errors.socialSecurityDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Pension</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="pensionMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.pensionMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.pensionMonthlyIncome &&
                                                            touched.pensionMonthlyIncome &&
                                                            errors.pensionMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="pensionAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.pensionAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.pensionAnnualGrowthRate &&
                                                            touched.pensionAnnualGrowthRate &&
                                                            errors.pensionAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="pensionStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.pensionStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.pensionStartAge &&
                                                            touched.pensionStartAge &&
                                                            errors.pensionStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="pensionEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.pensionEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.pensionEndAge &&
                                                            touched.pensionEndAge &&
                                                            errors.pensionEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'pensionDeductFromMonthly',
                                                                    id: 'pensionDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.pensionDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.pensionDeductFromMonthly &&
                                                            touched.pensionDeductFromMonthly &&
                                                            errors.pensionDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Other income (employment,
                                                    etc)
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="employmentMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.employmentMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.employmentMonthlyIncome &&
                                                            touched.employmentMonthlyIncome &&
                                                            errors.employmentMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="employmentAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.employmentAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.employmentAnnualGrowthRate &&
                                                            touched.employmentAnnualGrowthRate &&
                                                            errors.employmentAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="employmentStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.employmentStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.employmentStartAge &&
                                                            touched.employmentStartAge &&
                                                            errors.employmentStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="employmentEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.employmentEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.employmentEndAge &&
                                                            touched.employmentEndAge &&
                                                            errors.employmentEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'employmentDeductFromMonthly',
                                                                    id: 'employmentDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.employmentDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.employmentDeductFromMonthly &&
                                                            touched.employmentDeductFromMonthly &&
                                                            errors.employmentDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    401(k), Other qualified
                                                    plans
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="qualifiedPlansMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.qualifiedPlansMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.qualifiedPlansMonthlyIncome &&
                                                            touched.qualifiedPlansMonthlyIncome &&
                                                            errors.qualifiedPlansMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="qualifiedPlansAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.qualifiedPlansAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.qualifiedPlansAnnualGrowthRate &&
                                                            touched.qualifiedPlansAnnualGrowthRate &&
                                                            errors.qualifiedPlansAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="qualifiedPlansStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.qualifiedPlansStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.qualifiedPlansStartAge &&
                                                            touched.qualifiedPlansStartAge &&
                                                            errors.qualifiedPlansStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="qualifiedPlansEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.qualifiedPlansEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.qualifiedPlansEndAge &&
                                                            touched.qualifiedPlansEndAge &&
                                                            errors.qualifiedPlansEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'qualifiedPlansDeductFromMonthly',
                                                                    id: 'qualifiedPlansDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.qualifiedPlansDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.qualifiedPlansDeductFromMonthly &&
                                                            touched.qualifiedPlansDeductFromMonthly &&
                                                            errors.qualifiedPlansDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Savings & Investments
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="savingsInvestmentsMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.savingsInvestmentsMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.savingsInvestmentsMonthlyIncome &&
                                                            touched.savingsInvestmentsMonthlyIncome &&
                                                            errors.savingsInvestmentsMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="savingsInvestmentsAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.savingsInvestmentsAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.savingsInvestmentsAnnualGrowthRate &&
                                                            touched.savingsInvestmentsAnnualGrowthRate &&
                                                            errors.savingsInvestmentsAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="savingsInvestmentsStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.savingsInvestmentsStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.savingsInvestmentsStartAge &&
                                                            touched.savingsInvestmentsStartAge &&
                                                            errors.savingsInvestmentsStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="savingsInvestmentsEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.savingsInvestmentsEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.savingsInvestmentsEndAge &&
                                                            touched.savingsInvestmentsEndAge &&
                                                            errors.savingsInvestmentsEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'savingsInvestmentsDeductFromMonthly',
                                                                    id: 'savingsInvestmentsDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.savingsInvestmentsDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.savingsInvestmentsDeductFromMonthly &&
                                                            touched.savingsInvestmentsDeductFromMonthly &&
                                                            errors.savingsInvestmentsDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Desired benfit / Defined
                                                    contribution plan
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="desiredBenefitMonthlyIncome"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.desiredBenefitMonthlyIncome
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Monthly income
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.desiredBenefitMonthlyIncome &&
                                                            touched.desiredBenefitMonthlyIncome &&
                                                            errors.desiredBenefitMonthlyIncome}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="desiredBenefitAnnualGrowthRate"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.desiredBenefitAnnualGrowthRate
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Growth rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.desiredBenefitAnnualGrowthRate &&
                                                            touched.desiredBenefitAnnualGrowthRate &&
                                                            errors.desiredBenefitAnnualGrowthRate}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="desiredBenefitStartAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.desiredBenefitStartAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Start age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.desiredBenefitStartAge &&
                                                            touched.desiredBenefitStartAge &&
                                                            errors.desiredBenefitStartAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="desiredBenefitEndAge"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.desiredBenefitEndAge
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            End age
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.desiredBenefitEndAge &&
                                                            touched.desiredBenefitEndAge &&
                                                            errors.desiredBenefitEndAge}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Text>
                                                        Deduct from monthly
                                                    </Text>
                                                    <Radio.Group
                                                        onChange={(
                                                            e: RadioChangeEvent
                                                        ) => {
                                                            console.log(
                                                                'radio checked',
                                                                e.target.value
                                                            )
                                                            handleChange({
                                                                target: {
                                                                    type: 'text',
                                                                    name: 'desiredBenefitDeductFromMonthly',
                                                                    id: 'desiredBenefitDeductFromMonthly',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }}
                                                        value={
                                                            values.desiredBenefitDeductFromMonthly
                                                        }
                                                    >
                                                        <Radio value={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio value={false}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                    <div className="profile-form-error">
                                                        {errors.desiredBenefitDeductFromMonthly &&
                                                            touched.desiredBenefitDeductFromMonthly &&
                                                            errors.desiredBenefitDeductFromMonthly}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Federal rate</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="federalTaxRateCurrent"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.federalTaxRateCurrent
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Current rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.federalTaxRateCurrent &&
                                                            touched.federalTaxRateCurrent &&
                                                            errors.federalTaxRateCurrent}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="federalTaxRateRetirement"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.federalTaxRateRetirement
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Retirement rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.federalTaxRateRetirement &&
                                                            touched.federalTaxRateRetirement &&
                                                            errors.federalTaxRateRetirement}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>State/Local rate</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="stateLocalTaxRateCurrent"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.stateLocalTaxRateCurrent
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Current rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.stateLocalTaxRateCurrent &&
                                                            touched.stateLocalTaxRateCurrent &&
                                                            errors.stateLocalTaxRateCurrent}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="stateLocalTaxRateRetirement"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.stateLocalTaxRateRetirement
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Retirement rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.stateLocalTaxRateRetirement &&
                                                            touched.stateLocalTaxRateRetirement &&
                                                            errors.stateLocalTaxRateRetirement}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>Capital gains rate</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Space
                                                direction="horizontal"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="capitalGainsTaxRateCurrent"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.capitalGainsTaxRateCurrent
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Current rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.capitalGainsTaxRateCurrent &&
                                                            touched.capitalGainsTaxRateCurrent &&
                                                            errors.capitalGainsTaxRateCurrent}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="material-textfield">
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="capitalGainsTaxRateRetirement"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.capitalGainsTaxRateRetirement
                                                            }
                                                            className="material-textfield-input"
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Retirement rate
                                                        </label>
                                                    </div>

                                                    <div className="profile-form-error">
                                                        {errors.capitalGainsTaxRateRetirement &&
                                                            touched.capitalGainsTaxRateRetirement &&
                                                            errors.capitalGainsTaxRateRetirement}
                                                    </div>
                                                </Col>
                                            </Space>
                                        </Row>
                                    </Space>
                                </Col>
                                {/* <Col span={8}>
                                    <Upload
                                        action="/upload.do"
                                        listType="picture-card"
                                    >
                                        <div>
                                            <PlusOutlined rev={undefined} />
                                            <div style={{ marginTop: 8 }}>
                                                Select Profile Image
                                            </div>
                                        </div>
                                    </Upload>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col span={6}></Col>
                                <Col>
                                    <Button
                                        type="text"
                                        size={'middle'}
                                        onClick={() =>
                                            cancelShowProfileForm({
                                                for: showProfileForm.for,
                                                id: showProfileForm.id,
                                            })
                                        }
                                        style={{ color: '#00BAC9' }}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        size={'middle'}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                )}
            </Formik>
        )
    } else {
        return <>nada</>
    }
}

const mapStateToProps = (state: any) => {
    return {
        states: state.states,
        agency: state.agency,
        agent: state.agent,
        client: state.client,
    }
}

export default connect(mapStateToProps, {
    requestStates,
    requestAgency,
    requestAgent,
    requestClient,
})(ProfileForm)
