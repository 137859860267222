import React from 'react'
import { connect } from 'react-redux'
import { Row } from 'antd'
import { Tabs } from 'antd'
import AriaV2sPage from './AriaV2sPage'
import ProposalsPage from './ProposalsPage'
import ClientsPage from './ClientsPage'
import AgenciesPage from './AgenciesPage'
import IllustrationsPage from './IllustrationsPage'
import DashboardPage from './DashboardPage'
import BucketsPage from './BucketsPage'
import { STAFF_USER } from '../constants'
import { useFlagsmith } from 'flagsmith-react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../components/use_auth'
import ProfileManagement from '../components/ProfileManagement'

const TabPane = Tabs.TabPane

function Dashboard(props) {
    const history = useHistory()
    const auth = useAuth()
    const { hasFeature } = useFlagsmith()

    return (
        <Row>
            <div className="col-md-12">
                <Tabs
                    onChange={history.push}
                    defaultActiveKey={props.active || '/dashboard'}
                >
                    <TabPane tab="Dashboard" key="/dashboard">
                        <DashboardPage />
                    </TabPane>
                    <TabPane tab="Profiles" key="/profiles">
                        <ProfileManagement />
                    </TabPane>
                    <TabPane tab="Customers" key="/clients">
                        <ClientsPage />
                    </TabPane>
                    <TabPane tab="ARIA" key="/aria-v2">
                        <AriaV2sPage />
                    </TabPane>
                    {hasFeature('buckets') && (
                        <TabPane tab="Buckets" key="/buckets">
                            <BucketsPage />
                        </TabPane>
                    )}
                    {auth.roles.includes(STAFF_USER) && (
                        <TabPane tab="Proposals" key="/proposals">
                            <ProposalsPage />
                        </TabPane>
                    )}
                    {auth.roles.includes(STAFF_USER) && (
                        <TabPane tab="Illustrations" key="/illustrations">
                            <IllustrationsPage />
                        </TabPane>
                    )}
                    <TabPane tab="Agencies" key="/agencies">
                        <AgenciesPage />
                    </TabPane>
                </Tabs>
            </div>
        </Row>
    )
}

export default connect()(Dashboard)
