import {
    BUCKET_REPORTS_GET_REQUEST,
    BUCKET_REPORTS_GET_SUCCESS,
    BUCKET_REPORTS_GET_FAILURE,
    BUCKET_REPORT_CREATE_REQUEST,
    BUCKET_REPORT_CREATE_SUCCESS,
    BUCKET_REPORT_CREATE_FAILURE,
} from '../actions'

export default function bucketReport(state = { loading: false }, action) {
    switch (action.type) {
        case BUCKET_REPORT_CREATE_REQUEST:
        case BUCKET_REPORTS_GET_REQUEST:
            return { loading: true }
        case BUCKET_REPORT_CREATE_SUCCESS:
        case BUCKET_REPORTS_GET_SUCCESS:
            return { loading: false, data: action.data }
        case BUCKET_REPORT_CREATE_FAILURE:
        case BUCKET_REPORTS_GET_FAILURE:
            return { loading: false, error: action.error }
        default:
            return state
    }
}
