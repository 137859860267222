/*
 * Created by Sina Khelil on 2/1/16.
 */

import React from 'react'
import { Link } from 'react-router-dom'
import ErrorBoundary from '../shared_components/error_boundary'
import { Table, Divider, Card, Button } from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'

import 'react-table/react-table.css'

export default class AgencyList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            per_page: 25,
        }
    }

    render() {
        const { data, page, count, loading, error, pageChangeHandler } =
            this.props
        if (error) {
            return (
                <div className="has-error text-danger">
                    Oops, looks like something went wrong.
                </div>
            )
        } else {
            const columns = [
                {
                    title: 'Agency',
                    dataIndex: 'company',
                },
                {
                    title: 'City',
                    dataIndex: 'city',
                },
                {
                    title: 'State',
                    maxWidth: 45,
                    dataIndex: 'state',
                },
                {
                    title: 'Phone',
                    dataIndex: 'phone',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Action',
                    key: 'original',
                    render: (original, record) => (
                        <span>
                            <Link to={'/agencies/' + original.id}>View</Link>
                            <Divider type="vertical" />
                            <Link to={'/agencies/edit/' + original.id}>
                                Edit
                            </Link>
                        </span>
                    ),
                },
            ]

            const curr_pagination = {
                position: 'bottom',
                current: page + 1,
                defaultPageSize: 25,
                total: count,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['25', '50', '100'],
            }

            return (
                <Card
                    bodyStyle={{
                        padding: 0,
                        margin: 0,
                    }}
                    bordered={false}
                    title={''}
                    extra={
                        <Button type="primary">
                            <Link to="/agencies/add">
                                <Icon type="plus" />
                                New...
                            </Link>
                        </Button>
                    }
                >
                    <ErrorBoundary>
                        <Table
                            loading={loading}
                            pagination={curr_pagination}
                            onChange={pageChangeHandler}
                            columns={columns}
                            dataSource={data}
                        />
                        {/* <ReactTable
                manual
                data={data}
                loading={loading}
                pages={Math.ceil(count / perPage)}
                pageSizeOptions={[25, 50, 100]}
                defaultPageSize={25}
                minRows={0}
                sorted={sorted}
                columns={columns}
                onFetchData={pageChangeHandler}
                multiSort={false}
                className="-striped -highlight"
              /> */}
                    </ErrorBoundary>
                </Card>
            )
        }
    }
}
