/*
 * Created by Sina Khelil on 25/05/18.
 */

import React from 'react'
import { Formik, Form, Field, FieldArray, getIn } from 'formik'
import * as yup from 'yup'
import Select from 'react-select'
import BSField from '../shared_components/bs_field'
import { Form as AntForm, Upload, Card, Button, Col, Row } from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'

import { Link, useHistory } from 'react-router-dom'

const valSchema = yup.object().shape({
    clientId: yup.string().required(),
    importData: yup
        .array()
        .of(
            yup.object().shape({
                premium: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                premiumCharge: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                coiCharge: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                policyIssueCharge: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                adminCharge: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                bonusInterestCredit: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                reimbursement: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
                loan: yup
                    .number()
                    .typeError('Must be a number')
                    .integer()
                    .required(),
            })
        )
        .required(),
})
const ParseFileForm = (props) => {
    const history = useHistory()
    const {
        data,
        loading,
        requestCreateIllustration,
        requestFileParse,
        clearIllustration,
        clients,
        clientId,
    } = props
    if (loading || clients.loading) {
        return <div>Loading...</div>
    }

    const clientOptions = clients.data.map((client) => ({
        value: client.id,
        label: `${client.fullName} - ${client.agencyName} - ${client.agentName}`,
    }))

    const form_props = {
        beforeUpload: (file) => {
            requestFileParse([file])
            return false
        },
    }

    return (
        <Card
            title={
                <Row>
                    <Col span={8}>
                        <Link to="/illustrations" className="pull-left">
                            <Button icon="arrow-left" type="danger">
                                Cancel
                            </Button>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'center' }}>
                            Illustration - Parse File
                        </div>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            }
        >
            <div className="panel-body">
                {data.importData && data.importData.length > 0 ? (
                    <Formik
                        validationSchema={valSchema}
                        validateOnChange={false}
                        isInitialValid={() =>
                            valSchema.isValid({ importData: data.importData })
                        }
                        initialValues={{
                            selectedClient: undefined,
                            clientId: clientId || undefined,
                            importData: data.importData || [],
                        }}
                        onSubmit={(values) => {
                            requestCreateIllustration({ ...values, history })
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            isValid,
                            setFieldValue,
                        }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-md-12">
                                        {!clientId && (
                                            <BSField
                                                touched={touched.selectedClient}
                                                error={errors.selectedClient}
                                            >
                                                <label
                                                    className="control-label"
                                                    htmlFor="client"
                                                >
                                                    Client
                                                </label>
                                                <Select
                                                    name="client"
                                                    value={
                                                        values.selectedClient
                                                    }
                                                    options={clientOptions}
                                                    onChange={(selected) => {
                                                        setFieldValue(
                                                            'selectedClient',
                                                            selected
                                                        )
                                                        setFieldValue(
                                                            'clientId',
                                                            selected.value
                                                        )
                                                    }}
                                                />
                                            </BSField>
                                        )}
                                        <label className="control-label">
                                            Import Data
                                        </label>
                                        <button
                                            type="button"
                                            className="btn btn-info btn-xs pull-right"
                                            onClick={clearIllustration}
                                        >
                                            Reset
                                        </button>
                                        <table className="table table-striped table-responsive table-hover table-condensed">
                                            <thead>
                                                <tr>
                                                    <th>Year</th>
                                                    <th>Premium</th>
                                                    <th>Age</th>
                                                    <th>Loan</th>
                                                    {/* <th>Tax</th> */}
                                                    <th>COI</th>
                                                    <th>PIC</th>
                                                    <th>
                                                        Bonus Interest Credit
                                                    </th>
                                                    <th>Reimbursement</th>
                                                    <th>Premium Charge</th>
                                                    <th>Admin Charge</th>
                                                </tr>
                                            </thead>
                                            <FieldArray
                                                name="importData"
                                                render={() => (
                                                    <tbody>
                                                        {values.importData.map(
                                                            (
                                                                importLine,
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td className="small">
                                                                        {
                                                                            importLine.year
                                                                        }
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.premium`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.premium`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.premium`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.age`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.age`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.age`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.loan`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.loan`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.loan`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.coiCharge`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.coiCharge`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.coiCharge`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.policyIssueCharge`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.policyIssueCharge`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.policyIssueCharge`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.bonusInterestCredit`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.bonusInterestCredit`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.bonusInterestCredit`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.reimbursement`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.reimbursement`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.reimbursement`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.premiumCharge`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.premiumCharge`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.premiumCharge`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                    <td className="small">
                                                                        <BSField
                                                                            touched={getIn(
                                                                                touched,
                                                                                `importData.${index}.adminCharge`
                                                                            )}
                                                                            error={getIn(
                                                                                errors,
                                                                                `importData.${index}.adminCharge`
                                                                            )}
                                                                        >
                                                                            <Field
                                                                                type="number"
                                                                                name={`importData.${index}.adminCharge`}
                                                                            />
                                                                        </BSField>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                )}
                                            />
                                        </table>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary pull-right"
                                        disabled={!isValid}
                                    >
                                        Next...
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div>
                        <AntForm>
                            <AntForm.Item>
                                <div className="dropbox">
                                    <Upload.Dragger
                                        name="files"
                                        {...form_props}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                    </Upload.Dragger>
                                </div>
                            </AntForm.Item>
                        </AntForm>
                        <div className="form-group">
                            <Button
                                type="primary"
                                className="pull-right"
                                disabled
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default ParseFileForm
