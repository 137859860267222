/*****************************
 *   Author: Sina Khelil
 *   Date: Jun 5, 2018
 ******************************/

import { Button, Card } from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import AgentAddForm from './form2'

const AgentAdd = (props) => {
    const { agencyId } = useParams()

    return (
        <Card
            title="Add Agent"
            extra={
                <Button type="danger" onClick={() => {}}>
                    <Link to={`/agencies/${agencyId}`}>
                        <Icon type="arrow-left" />
                        Back
                    </Link>
                </Button>
            }
        >
            <AgentAddForm
                data={[]}
                handleSubmit={props.handleSubmit}
                errors={props.errors}
                agencyId={agencyId}
            />
        </Card>
    )
}

export default AgentAdd
