import { Button, Card, Col, Input, Row, Space, Spin, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {
    requestBucketReportSegments,
    requestBucketReports,
    requestClient,
    requestCreateBucketReport,
    requestUpdateBucketReport,
} from '../../actions'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
const { Search } = Input
const { Title, Text } = Typography
import { Formik, Form } from 'formik'
import VaultCard from './VaultCard'
import moment from 'moment'
import IULCard from './IULCard'
import LongTermCareCard from './LongTermCareCard'
import AnnuityCard from './AnnuityCard'
import * as yup from 'yup'

const onSearch = (value: any) => console.log(value)

const incomeGoalApproachValidationSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Report name must be at least 3 characters long.')
        .max(50)
        .required('Report name is required.'),
    approach: yup.string().max(20).required('Approach is required'),
    goalAmount: yup.number().required('Goal amount is required'),
    inflationPercentage: yup.number().optional(),
    availableBalance: yup.number().optional(),
    customer: yup.string().required('Client ID is required'),
})

const availableFundsValidationSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Report name must be at least 3 characters long.')
        .max(50)
        .required('Report name is required.'),
    approach: yup.string().max(20).required('Approach is required'),
    availableBalance: yup.number().optional(),
    customer: yup.string().required('Client ID is required'),
})

export enum SegmentType {
    'annuity',
    'vault',
    'longTermCare',
    'iul',
}

export enum BucketApproachEnum {
    'income_goal' = 'income_goal',
    'available_funds' = 'available_funds',
}

export enum ManuallyEditedEnum {
    none,
    monthlyIncome,
    inflationAdjustedMonthlyIncome,
}

// TODO: set props type
const BucketTool = (props: any) => {
    const history = useHistory()
    const params = useParams<{ clientId: string; bucketId: string }>()
    const { clientId, bucketId } = params
    const { client, bucketReports, bucketReportSegments } = props

    const [vaults, setVaults] = useState<any[]>([])
    const [longTermCares, setLongTermCares] = useState<any[]>([])
    const [iuls, setIuls] = useState<any[]>([])
    const [annuities, setAnnuities] = useState<any[]>([])
    const [bucketReportForm, setBucketReportForm] = useState<any>(undefined)
    const [manuallyEditedAnnuity, setManuallyEditedAnnuity] =
        useState<ManuallyEditedEnum>(ManuallyEditedEnum.none)
    const [bucketApproach, setBucketApproach] = useState('')
    const [endingBalance, setEndingBalance] = useState(0)
    const [availableBalance, setAvailableBalance] = useState<
        number | undefined
    >(undefined)

    const totalSegmentsCount =
        vaults.length + longTermCares.length + iuls.length + annuities.length

    console.log('CLIENT ID: ', clientId)
    console.log('BUCKET REPORT ID: ', bucketId)
    useEffect(() => {
        props.requestClient(clientId)
        props.requestBucketReports(clientId)
    }, [])

    useEffect(() => {
        if (bucketReports.data && bucketReports.data.length > 0 && bucketId) {
            const report = bucketReports.data.find(
                (report: any) => report.id == bucketId
            )
            props.requestBucketReportSegments(bucketId)
            setBucketReportForm(report)
        }
    }, [bucketReports])

    useEffect(() => {
        if (bucketReportSegments.vaults) {
            const vaultsToSet = bucketReportSegments.vaults.map(
                (vault: any, index: any) => {
                    return {
                        position: totalSegmentsCount + index,
                        type: SegmentType.vault,
                        ...vault,
                    }
                }
            )
            setVaults(vaultsToSet)
        }
        if (bucketReportSegments.longTermCares) {
            const longTermCaresToSet = bucketReportSegments.longTermCares.map(
                (vault: any, index: any) => {
                    return {
                        position: totalSegmentsCount + index,
                        type: SegmentType.longTermCare,
                        ...vault,
                    }
                }
            )
            setLongTermCares(longTermCaresToSet)
        }
        if (bucketReportSegments.iuls) {
            const iulsToSet = bucketReportSegments.iuls.map(
                (vault: any, index: any) => {
                    return {
                        position: totalSegmentsCount + index,
                        type: SegmentType.iul,
                        ...vault,
                    }
                }
            )
            setIuls(iulsToSet)
        }
        if (bucketReportSegments.annuities) {
            const annuitiesToSet = bucketReportSegments.annuities.map(
                (vault: any, index: any) => {
                    return {
                        position: totalSegmentsCount + index,
                        type: SegmentType.annuity,
                        ...vault,
                    }
                }
            )
            setAnnuities(annuitiesToSet)
        }
    }, [bucketReportSegments])

    if (client && !client.loading) {
        function handleSetVaults(
            position: number,
            which: string,
            value: string
        ) {
            const segment = vaults.find((vault) => vault.position == position)
            if (which == 'startingBalance') {
                segment.startingBalance = value
            } else {
                segment.growthRate = value
            }
            setVaults([...vaults])

            if (which === 'startingBalance') {
                setEndingBalance(
                    calculateSummaryEndingBalance(availableBalance)
                )
            }
        }

        function handleSetLongTermCares(
            position: number,
            which: string,
            value: string
        ) {
            const segment = longTermCares.find(
                (longTermCare) => longTermCare.position == position
            )
            segment[which] = value
            setLongTermCares([...longTermCares])

            if (which === 'totalPremium') {
                setEndingBalance(
                    calculateSummaryEndingBalance(availableBalance)
                )
            }
        }

        function handleSetIuls(position: number, which: string, value: string) {
            const segment = iuls.find((iul) => iul.position == position)
            segment[which] = value
            setIuls([...iuls])

            if (which === 'totalPremium') {
                setEndingBalance(
                    calculateSummaryEndingBalance(availableBalance)
                )
            }
        }

        function handleSetAnnuities(
            position: number,
            which: string,
            value: any,
            manuallyEdited?: boolean
        ) {
            const segment = annuities.find(
                (annuity) => annuity.position == position
            )
            segment[which] = value
            setAnnuities([...annuities])
            if (manuallyEdited && which == 'monthlyIncome') {
                setManuallyEditedAnnuity(ManuallyEditedEnum.monthlyIncome)
            }
            if (manuallyEdited && which == 'inflationAdjustedMonthlyIncome') {
                setManuallyEditedAnnuity(
                    ManuallyEditedEnum.inflationAdjustedMonthlyIncome
                )
            }
        }

        function handleRemove(segmentType: SegmentType, position: number) {
            if (segmentType === SegmentType.vault) {
                var arrayCopy = [...vaults]
                arrayCopy.splice(
                    arrayCopy.findIndex((vault) => vault.position == position),
                    1
                )
                setVaults(arrayCopy)
            } else if (segmentType === SegmentType.annuity) {
                var arrayCopy = [...annuities]
                arrayCopy.splice(
                    arrayCopy.findIndex(
                        (annuity) => annuity.position == position
                    ),
                    1
                )
                setAnnuities(arrayCopy)
            } else if (segmentType === SegmentType.longTermCare) {
                var arrayCopy = [...longTermCares]
                arrayCopy.splice(
                    arrayCopy.findIndex(
                        (longTermCare) => longTermCare.position == position
                    ),
                    1
                )
                setLongTermCares(arrayCopy)
            } else if (segmentType === SegmentType.iul) {
                var arrayCopy = [...iuls]
                arrayCopy.splice(
                    arrayCopy.findIndex((iul) => iul.position == position),
                    1
                )
                setIuls(arrayCopy)
            }
        }

        function calculateAnnuityStartYear() {
            if (annuities.length > 0) {
                const lastStartYear = annuities[annuities.length - 1].startYear
                const deferralYears =
                    annuities[annuities.length - 1].deferralYears
                if (deferralYears) {
                    return parseInt(lastStartYear) + parseInt(deferralYears)
                }
                return lastStartYear
            }
            return moment().year()
        }

        function calculateAnnuityMonthlyIncome(
            goalAmount: any,
            inflationPercentage: any
        ): number {
            if (inflationPercentage) {
                const deferralYears = annuities[annuities.length - 1]
                    ? annuities[annuities.length - 1].deferralYears
                    : 0
                // Monthly Income Amount = year 1 value (input) * (1 + Inflation Rate) ^ Deferral Years
                // (5000 * 1.03 ** 5).toFixed(2)
                return parseFloat(
                    (
                        parseFloat(goalAmount) *
                        (1 + parseFloat(inflationPercentage) / 100) **
                            parseInt(deferralYears)
                    ).toFixed(2)
                )
            } else {
                return goalAmount
            }
        }

        const segments = [...annuities, ...vaults, ...longTermCares, ...iuls]
        const allSegments = segments.sort((a, b) =>
            a.position > b.position ? 1 : -1
        )

        function calculateSummaryTotalRequired() {
            //total needed is the sum of total required + total IUL premium + LTC premium + vault starting balance

            let totalRequiredSum = 0
            const elements = Array.from(
                document.getElementsByClassName('annuityTotalNeeded')
            )
            elements.forEach((element) => {
                if (element.textContent) {
                    totalRequiredSum += parseFloat(
                        element.textContent.replace(/[^\d.]/g, '')
                    )
                }
            })

            iuls.forEach((iul) => {
                if (iul.totalPremium) {
                    totalRequiredSum += parseFloat(iul.totalPremium)
                }
            })

            longTermCares.forEach((longTermCare) => {
                if (longTermCare.totalPremium) {
                    totalRequiredSum += parseFloat(longTermCare.totalPremium)
                }
            })

            vaults.forEach((vault) => {
                if (vault.startingBalance) {
                    totalRequiredSum += parseFloat(vault.startingBalance)
                }
            })

            return totalRequiredSum
        }

        function calculateSummaryEndingBalance(availableBalance: any) {
            let endingBalance = availableBalance
            if (bucketApproach == BucketApproachEnum.income_goal) {
                // Ending balance = Would this be all of the annuities "monthly income" added together?
                annuities.forEach((annuity) => {
                    if (
                        bucketReportForm &&
                        bucketReportForm.inflationPercentage
                    ) {
                        endingBalance += parseFloat(
                            annuity.inflationAdjustedMonthlyIncome
                        )
                    } else {
                        annuity.monthlyIncome
                            ? (endingBalance += parseFloat(
                                  annuity.monthlyIncome
                              ))
                            : 0
                    }
                })
            } else if (bucketApproach == BucketApproachEnum.available_funds) {
                // if not a annuity segment first,
                // then subtract total premium and starting balance from Available funds
                if (
                    allSegments.length > 0 &&
                    !('payoutMethod' in allSegments[0])
                ) {
                    endingBalance = availableBalance
                    allSegments.forEach((segment) => {
                        if ('startingBalance' in segment) {
                            endingBalance -= segment.startingBalance
                        } else if ('totalPremium' in segment) {
                            endingBalance -= segment.totalPremium
                        }

                        /*else if ('payoutMethod' in segment) {
                            segment.monthlyIncome
                                ? (endingBalance += parseFloat(
                                      segment.monthlyIncome
                                  ))
                                : 0
                        }*/
                    })
                }
            }
            return endingBalance
        }

        function calculateSummaryYearsOfIncome() {
            // Years of income = all of the annuities "income years" field added together
            let income = 0
            annuities.forEach((annuity) =>
                annuity.incomeYears
                    ? (income += parseInt(annuity.incomeYears))
                    : 0
            )
            return income
        }

        function calculateAnnuityAvailableFunds() {
            return endingBalance / annuities.length
        }

        return (
            <>
                <Row>
                    <Col span={1}></Col>
                    <Col span={16}>
                        <Title level={5} style={{ margin: 0 }}>
                            <span
                                style={{ cursor: 'pointer' }}
                                className="primary-color"
                                onClick={() =>
                                    history.replace(
                                        `/profiles/agency/${client.agency.value}`
                                    )
                                }
                            >
                                {client.agency.label}
                            </span>{' '}
                            {'>'}{' '}
                            <span className="primary-color">
                                {client.selectedAgent.label}
                            </span>{' '}
                            {'>'} <span>{client.fullName}</span>
                        </Title>
                    </Col>

                    <Col span={1}></Col>
                    <Col span={6}>
                        <Search
                            placeholder="Agency, advisor, or client"
                            onSearch={onSearch}
                            enterButton
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {' '}
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Preview
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Duplicate
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Email client report
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            onClick={() => setBucketReportForm(true)}
                        >
                            Start a new report
                        </Button>
                    </Col>
                    <Col span={6}></Col>
                </Row>
                <Formik
                    enableReinitialize
                    validationSchema={
                        bucketApproach === BucketApproachEnum.income_goal
                            ? incomeGoalApproachValidationSchema
                            : availableFundsValidationSchema
                    }
                    initialValues={
                        bucketReportForm
                            ? {
                                  name: bucketReportForm.name,
                                  approach: bucketReportForm.approach,
                                  goalAmount: bucketReportForm.goalAmount,
                                  inflationPercentage:
                                      bucketReportForm.inflationPercentage,
                                  availableBalance:
                                      bucketReportForm.availableBalance,
                                  customer: clientId,
                              }
                            : {
                                  name: undefined,
                                  approach: undefined,
                                  goalAmount: undefined,
                                  inflationPercentage: undefined,
                                  availableBalance: undefined,
                                  customer: clientId,
                              }
                    }
                    onSubmit={(values, actions) => {
                        if (bucketReportForm.id) {
                            props.requestUpdateBucketReport(
                                bucketReportForm.id,
                                {
                                    ...values,
                                    vaults: vaults,
                                    longTermCares: longTermCares,
                                    iuls: iuls,
                                    annuities: annuities,
                                }
                            )
                        } else {
                            props.requestCreateBucketReport({
                                ...values,
                                vaults: vaults,
                                longTermCares: longTermCares,
                                iuls: iuls,
                                annuities: annuities,
                            })
                        }
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        dirty,
                        handleSubmit,
                        handleChange,
                        isSubmitting,
                    }) => (
                        <Form>
                            <Row style={{ paddingBottom: 15 }}>
                                <Col
                                    span={5}
                                    style={{ paddingLeft: 15, display: 'flex' }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                            backgroundColor: '#FAFAFA',
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                <Title level={5}>
                                                    Client Info
                                                </Title>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={16}>
                                                <Text>{client.fullName}</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    {client.gender == 1
                                                        ? `Male, ${client.currentAge}`
                                                        : `Female, ${client.currentAge}`}
                                                </Text>
                                            </Col>
                                        </Row>
                                        {client.spouseFullName.length > 1 && (
                                            <Row>
                                                <Col span={16}>
                                                    <Text>
                                                        {client.spouseFullName}
                                                    </Text>
                                                </Col>
                                                <Col
                                                    span={8}
                                                    style={{ textAlign: 'end' }}
                                                >
                                                    <Text type="secondary">
                                                        {client.spouseGender ==
                                                        1
                                                            ? 'Male'
                                                            : 'Female'}
                                                        ,{client.spouseAge}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        )}
                                        <br />
                                        <Row>
                                            <Col>{client.address}</Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {client.state},{client.postal}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>{client.phone}</Col>
                                        </Row>
                                        <br />

                                        <Row>
                                            <Col span={16}>
                                                <Text>Social security</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.socialSecurityIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>Pension</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    ${client.pensionIncome}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>Employment</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    ${client.otherIncome}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>Retirement plans</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    $
                                                    {client.qualifiedPlanIncome}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>
                                                    Savings & investments
                                                </Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.savingsInvestmentsIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>
                                                    Defined benefit or plan
                                                </Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.definedContributionPlanIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={16}>
                                                <Text>Reserves</Text>
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text type="secondary">
                                                    $0.00
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />
                                        <Row>
                                            <Col>
                                                <Title level={5}>Reports</Title>
                                            </Col>
                                        </Row>
                                        <br />
                                        {!bucketReports.loading &&
                                            bucketReports.data &&
                                            bucketReports.data.map(
                                                (report: any) => (
                                                    <>
                                                        <Row
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setBucketReportForm(
                                                                    undefined
                                                                )
                                                                props.requestBucketReportSegments(
                                                                    report.id
                                                                )
                                                                setBucketReportForm(
                                                                    {
                                                                        ...report,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <Col span={15}>
                                                                <Text className="primary-color">
                                                                    {
                                                                        report.name
                                                                    }
                                                                </Text>
                                                            </Col>
                                                            <Col span={9}>
                                                                <Text type="secondary">
                                                                    {moment(
                                                                        report.createdOn
                                                                    ).format(
                                                                        'MM/DD/YYYY'
                                                                    )}
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                    </>
                                                )
                                            )}
                                    </Card>
                                </Col>

                                <Col
                                    span={14}
                                    style={{ paddingLeft: 15, display: 'flex' }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                        }}
                                    >
                                        <Space
                                            direction="vertical"
                                            size="middle"
                                            style={{ display: 'flex' }}
                                        >
                                            <Row>
                                                <Col span={6}>
                                                    <Card
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Space
                                                            direction="vertical"
                                                            size="middle"
                                                            style={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col>
                                                                    <div className="material-textfield">
                                                                        <select
                                                                            name="approach"
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                                setBucketApproach(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }}
                                                                            value={
                                                                                values.approach
                                                                            }
                                                                            disabled={
                                                                                !bucketReportForm
                                                                            }
                                                                            placeholder=""
                                                                            className="material-textfield-input"
                                                                        >
                                                                            <option value="none">
                                                                                ---
                                                                            </option>
                                                                            <option value="income_goal">
                                                                                Income
                                                                                goal
                                                                            </option>
                                                                            <option value="available_funds">
                                                                                Available
                                                                                funds
                                                                            </option>
                                                                        </select>
                                                                        <label
                                                                            style={{
                                                                                fontWeight:
                                                                                    'normal',
                                                                            }}
                                                                        >
                                                                            Approach
                                                                        </label>
                                                                    </div>
                                                                    <div className="profile-form-error">
                                                                        {errors.approach &&
                                                                            touched.approach &&
                                                                            errors.approach}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {values.approach ==
                                                                BucketApproachEnum.income_goal && (
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="material-textfield">
                                                                                <input
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="goalAmount"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    value={
                                                                                        values.goalAmount
                                                                                    }
                                                                                    className="material-textfield-input"
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'normal',
                                                                                    }}
                                                                                >
                                                                                    Goal
                                                                                    amount
                                                                                </label>
                                                                            </div>
                                                                            <div className="profile-form-error">
                                                                                {errors.goalAmount &&
                                                                                    touched.goalAmount &&
                                                                                    errors.goalAmount}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="material-textfield">
                                                                                <input
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="inflationPercentage"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    value={
                                                                                        values.inflationPercentage
                                                                                    }
                                                                                    className="material-textfield-input"
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'normal',
                                                                                    }}
                                                                                >
                                                                                    Inflation
                                                                                    %
                                                                                </label>
                                                                            </div>
                                                                            <div className="profile-form-error">
                                                                                {errors.inflationPercentage &&
                                                                                    touched.inflationPercentage &&
                                                                                    errors.inflationPercentage}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                            {values.approach && (
                                                                <Row>
                                                                    <Col>
                                                                        <div className="material-textfield">
                                                                            <input
                                                                                placeholder=""
                                                                                type="text"
                                                                                name="availableBalance"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setEndingBalance(
                                                                                        calculateSummaryEndingBalance(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    )
                                                                                    setAvailableBalance(
                                                                                        parseFloat(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    )
                                                                                    handleChange(
                                                                                        e
                                                                                    )
                                                                                }}
                                                                                value={
                                                                                    values.availableBalance
                                                                                }
                                                                                className="material-textfield-input"
                                                                            />
                                                                            <label
                                                                                style={{
                                                                                    fontWeight:
                                                                                        'normal',
                                                                                }}
                                                                            >
                                                                                Available
                                                                                funds
                                                                            </label>
                                                                        </div>
                                                                        <div className="profile-form-error">
                                                                            {errors.availableBalance &&
                                                                                touched.availableBalance &&
                                                                                errors.availableBalance}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            <Row>
                                                                <Col>
                                                                    <Title
                                                                        level={
                                                                            5
                                                                        }
                                                                        disabled={
                                                                            !bucketReportForm
                                                                        }
                                                                    >
                                                                        Add a
                                                                        segment
                                                                    </Title>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setAnnuities(
                                                                                [
                                                                                    ...annuities,
                                                                                    {
                                                                                        type: SegmentType.annuity,
                                                                                        position:
                                                                                            totalSegmentsCount +
                                                                                            1,
                                                                                        startYear:
                                                                                            calculateAnnuityStartYear(),
                                                                                        deferralYears:
                                                                                            undefined,
                                                                                        monthlyIncome:
                                                                                            calculateAnnuityMonthlyIncome(
                                                                                                values.goalAmount,
                                                                                                values.inflationPercentage
                                                                                            ),
                                                                                        incomeYears:
                                                                                            undefined,
                                                                                        inflationAdjustedMonthlyIncome:
                                                                                            undefined,
                                                                                        premiumBonusPercentage:
                                                                                            undefined,
                                                                                        rollupPercentage:
                                                                                            bucketApproach ==
                                                                                            BucketApproachEnum.available_funds
                                                                                                ? 5
                                                                                                : undefined,
                                                                                        rateOfReturnPercentage:
                                                                                            bucketApproach ==
                                                                                            BucketApproachEnum.available_funds
                                                                                                ? 5
                                                                                                : undefined,
                                                                                        withdrawalRatePercentage:
                                                                                            undefined,
                                                                                        payoutMethod:
                                                                                            'income_rider',
                                                                                        starting_value:
                                                                                            undefined,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            totalSegmentsCount ==
                                                                                10
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Annuity
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setLongTermCares(
                                                                                [
                                                                                    ...longTermCares,
                                                                                    {
                                                                                        type: SegmentType.longTermCare,
                                                                                        position:
                                                                                            totalSegmentsCount +
                                                                                            1,
                                                                                        total_premium:
                                                                                            undefined,
                                                                                        annual_benefit:
                                                                                            undefined,
                                                                                        death_benefit:
                                                                                            undefined,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            totalSegmentsCount ==
                                                                                10
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Long
                                                                        term
                                                                        care
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setIuls(
                                                                                [
                                                                                    ...iuls,
                                                                                    {
                                                                                        type: SegmentType.iul,
                                                                                        position:
                                                                                            totalSegmentsCount +
                                                                                            1,
                                                                                        total_premium:
                                                                                            undefined,
                                                                                        annual_income:
                                                                                            undefined,
                                                                                        increase_percentage:
                                                                                            undefined,
                                                                                        start_year:
                                                                                            undefined,
                                                                                        end_year:
                                                                                            undefined,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            totalSegmentsCount ==
                                                                                10
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Ind.
                                                                        Univ.
                                                                        life
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setVaults(
                                                                                [
                                                                                    ...vaults,
                                                                                    {
                                                                                        type: SegmentType.vault,
                                                                                        position:
                                                                                            totalSegmentsCount +
                                                                                            1,
                                                                                        startingBalance:
                                                                                            undefined,
                                                                                        growthRate:
                                                                                            undefined,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            totalSegmentsCount ==
                                                                                10
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Vault
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Space>
                                                    </Card>
                                                </Col>
                                                <Col
                                                    span={18}
                                                    style={{
                                                        backgroundColor:
                                                            '#BDBDBD',
                                                    }}
                                                >
                                                    {bucketReportForm &&
                                                        allSegments.length >
                                                            0 && (
                                                            <Row
                                                                wrap={false}
                                                                style={{
                                                                    overflowX:
                                                                        'scroll',
                                                                    overflowY:
                                                                        'hidden',
                                                                    paddingTop: 15,
                                                                    paddingBottom: 15,
                                                                }}
                                                            >
                                                                {allSegments.map(
                                                                    (
                                                                        segment
                                                                    ) => {
                                                                        if (
                                                                            segment.type ===
                                                                            SegmentType.annuity
                                                                        ) {
                                                                            return (
                                                                                <AnnuityCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleSetAnnuities={
                                                                                        handleSetAnnuities
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    bucketApproach={
                                                                                        values.approach
                                                                                    }
                                                                                    goalAmount={
                                                                                        values.goalAmount
                                                                                    }
                                                                                    inflationPercentage={
                                                                                        values.inflationPercentage
                                                                                    }
                                                                                    availableFunds={
                                                                                        bucketApproach ==
                                                                                        BucketApproachEnum.available_funds
                                                                                            ? calculateAnnuityAvailableFunds()
                                                                                            : undefined
                                                                                    }
                                                                                    manuallyEdited={
                                                                                        manuallyEditedAnnuity
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.longTermCare
                                                                        ) {
                                                                            return (
                                                                                <LongTermCareCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleSetLongTermCares={
                                                                                        handleSetLongTermCares
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.iul
                                                                        ) {
                                                                            return (
                                                                                <IULCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleSetIuls={
                                                                                        handleSetIuls
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.vault
                                                                        ) {
                                                                            return (
                                                                                <VaultCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleSetVaults={
                                                                                        handleSetVaults
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </Row>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col
                                    span={5}
                                    style={{
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        display: 'flex',
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                            backgroundColor: '#FAFAFA',
                                        }}
                                    >
                                        <Space
                                            direction="vertical"
                                            size="middle"
                                            style={{ display: 'flex' }}
                                        >
                                            <Row>
                                                <Col>
                                                    {bucketReportForm ? (
                                                        <>
                                                            <div className="material-textfield">
                                                                <input
                                                                    placeholder=""
                                                                    name="name"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    value={
                                                                        values.name
                                                                    }
                                                                    className="material-textfield-input"
                                                                />
                                                                <label
                                                                    style={{
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    Report name
                                                                </label>
                                                            </div>
                                                            <div className="profile-form-error">
                                                                {errors.name &&
                                                                    touched.name &&
                                                                    errors.name}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Title
                                                            level={5}
                                                            disabled
                                                        >
                                                            Report name
                                                        </Title>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Space
                                                direction="vertical"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Row>
                                                    <Col>
                                                        <Title
                                                            level={5}
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Summary
                                                        </Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Total required
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={12}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            {calculateSummaryTotalRequired()}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={14}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Ending balance
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={10}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            ${endingBalance}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={14}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Years of income
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={10}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            {calculateSummaryYearsOfIncome()}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                        >
                                                            Preview
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                        >
                                                            Duplicate
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                        >
                                                            Email client report
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                            onClick={() => {
                                                                handleSubmit()
                                                            }}
                                                        >
                                                            Save report
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    } else {
        return <Spin size="large" />
    }
}

const mapStateToProps = (state: any) => {
    return {
        client: state.client,
        bucketReports: state.bucketReports,
        bucketReportSegments: state.bucketReportSegments,
    }
}

export default connect(mapStateToProps, {
    requestClient,
    requestCreateBucketReport,
    requestBucketReports,
    requestBucketReportSegments,
    requestUpdateBucketReport,
})(BucketTool)
