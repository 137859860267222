import { Button, Card, Row, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { IncomeComps } from '../..'
import { get } from '../../services/api'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'

const IncomeCompList = () => {
    const [page, setPage] = useState(1)
    const [sorted, setSorted] = useState<string>()
    const [filters, setFilters] = useState<string>()

    const incomeComps = useQuery<IncomeComps>(
        ['incomeComps', page],
        () =>
            get('/api/v1/income-comps/', {
                params: {
                    page: page,
                    per_page: 25,
                    sorted: sorted,
                    filters: filters,
                },
            }).then((res: AxiosResponse) => res.data as IncomeComps),
        { keepPreviousData: true }
    )

    // retarded typescript workaround
    const right: 'right' | 'left' | 'center' | undefined = 'right'

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdOn',
            key: 'created_on',
            width: 170,
            sorter: true,
            render: (value: string) => moment(value).format('MM/DD/Y H:mm'),
        },
        {
            title: 'Customer',
            dataIndex: 'customer.name',
        },
        {
            title: 'Age',
            width: 60,
            align: right,
            dataIndex: 'customerAge',
        },
        {
            title: 'Reallocate Amount',
            width: 200,
            align: right,
            render: (value: number) => numeral(value).format('0,0'),
            dataIndex: 'amountToReallocate',
        },
        {
            title: 'Last Modified',
            dataIndex: 'modifiedOn',
            width: 170,
            key: 'modified_on',
            sorter: true,
            render: (value: string) => moment(value).format('MM/DD/Y H:mm'),
        },
        {
            title: 'Actions',
            width: 90,
            render: (original: { id: string }) => (
                <span>
                    <Link to={'/income-comps/' + original.id}>View</Link>
                </span>
            ),
        },
    ]

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any
    ) => {
        if (sorter.columnKey !== undefined)
            setSorted(
                `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
            )

        if (
            Object.keys(filters).includes('distribution_option') &&
            filters.distribution_option!.length > 0
        ) {
            setFilters(
                `distribution_option|${filters.distribution_option!.join(',')}`
            )
        }
        setPage(pagination.current! - 1)
    }

    return (
        <Card
            title="Income Tax Comparisons"
            style={{ minHeight: 300 }}
            extra={
                <Row>
                    <Link to={'/income-comps/add'}>
                        <Button type="default" shape="round" icon="plus" />
                    </Link>
                </Row>
            }
        >
            <Row>
                <Table
                    columns={columns}
                    rowKey={(record) => String(record.id)}
                    dataSource={incomeComps.data ? incomeComps.data.data : []}
                    pagination={{ current: page, pageSize: 25 }}
                    loading={incomeComps.isLoading}
                    onChange={handleTableChange}
                />
            </Row>
        </Card>
    )
}

export default IncomeCompList
