import {
    Button,
    Card,
    DatePicker,
    Divider,
    Form,
    message,
    Popover,
    Table,
} from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'

import FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { requestProposalLinkViews } from '../../actions'
import IPAddressDetail from '../../components/shared_components/ip_address_details'
import { downloadProposalLinkViews } from '../../services/proposal.service'

const { RangePicker } = DatePicker

const ReportProposalLinkVIews = (props) => {
    const [formData, setFormData] = React.useState({
        dateRange: [],
        dateString: [],
        downloading: false,
    })

    useEffect(() => {
        props.requestProposalLinkViews({
            page: props.page,
            per_page: props.pageSize,
            sorted: props.sorted,
        })
    }, [])

    const handleSubmit = () => {}

    // const expandedRowRender = (record) => {
    //     return (
    //         <div>
    //             <b>Created By:</b> {record.proposalLink.createdBy.username} -{' '}
    //             {record.proposalLink.createdBy.email}
    //             <br />
    //             <b>Originally Sent To:</b> {record.proposalLink.email}
    //         </div>
    //     )
    // }

    const pageChangeHandler = (state) => {
        props.requestProposalLinkViews({
            page: state.current - 1,
            per_page: state.pageSize,
            sorting: null,
        })
    }

    const onDownload = () => {
        setFormData({ ...formData, downloading: true })
        downloadProposalLinkViews({
            sorting: null,
            view_date_range: formData.dateRange.length
                ? formData.dateString
                : null,
        })
            .then((res) => {
                setFormData({ ...formData, downloading: false })
                const header = JSON.stringify([
                    'Agent',
                    'Agent Email',
                    'Customer Name',
                    'Send To',
                    'Send Date',
                    'View Date',
                    'View User',
                    'View Email',
                    'IP Address',
                    'Link',
                    'Browser',
                ]).slice(1, -1)

                const downloadData = res.data.map((r) => {
                    return JSON.stringify([
                        r.proposalLink.createdBy.username,
                        r.proposalLink.createdBy.email,
                        r.proposalLink.proposal.customerName,
                        r.proposalLink.email,
                        moment(r.proposalLink.sendDate).format('MM/DD/YYYY'),
                        moment(r.viewDate).format('MM/DD/YYYY'),
                        r.linkViewer.name,
                        r.linkViewer.email,
                        r.ipaddress.ipaddress,
                        r.proposalLink.linkUuid,
                        r.userAgent,
                    ]).slice(1, -1)
                })

                const csvData = header + '\n' + downloadData.join('\n')
                var data = new Blob([csvData], {
                    type: 'text/csv',
                })
                const downloadFileName =
                    `Link View Report` +
                    (formData.dateRange.length
                        ? ' [' + formData.dateString.join(' - ') + ']'
                        : '') +
                    '.csv'
                FileSaver.saveAs(data, downloadFileName)
            })
            .catch((err) => {
                setFormData({ ...formData, downloading: false })
                message.error('Error downloading data.')
            })
    }

    const onDateRangeChange = (date, dateString) => {
        setFormData({ ...formData, dateRange: date, dateString: dateString })
        props.requestProposalLinkViews({
            page: 0,
            per_page: props.pageSize,
            sorting: null,
            view_date_range: date.length ? dateString : null,
        })
    }

    const { link_views, count, page, loading } = props

    const curr_pagination = {
        position: 'bottom',
        current: page + 1,
        defaultPageSize: 25,
        total: count,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    }

    const columns = [
        {
            title: 'Agent',
            render: (record) => (
                <Popover
                    content={
                        <div>
                            <b>Created By:</b>{' '}
                            {record.proposalLink.createdBy.username} -{' '}
                            {record.proposalLink.createdBy.email}
                            <br />
                            <b>Originally Sent To:</b>{' '}
                            {record.proposalLink.email}
                            <br />
                            <b>Send Date:</b>{' '}
                            {moment(record.proposalLink.sendDate).format(
                                'MM/DD/YYYY'
                            )}
                            <br />
                            <b>Browser:</b> {record.userAgent}
                        </div>
                    }
                    title="More Info"
                >
                    <Button type="link">
                        {record.proposalLink.createdBy.username}
                    </Button>
                </Popover>
            ),
        },
        {
            title: 'View Date',
            dataIndex: 'viewDate',
            key: 'viewDate',
            render: (viewDate) => moment(viewDate).format('MM/DD/YYYY'),
        },
        {
            title: 'IP Address',
            dataIndex: 'ipaddress',
            key: 'ipaddress',
            render: (ipaddress) => (
                <Popover
                    content={<IPAddressDetail {...ipaddress} />}
                    title={ipaddress.ipaddress}
                >
                    <Button type="link">{ipaddress.ipaddress}</Button>
                </Popover>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'proposalLink',
            key: 'proposalLink',
            render: (proposalLink) => proposalLink.proposal.customerName,
        },
        {
            title: 'Viewer Name',
            dataIndex: 'linkViewer',
            key: 'linkViewer',
            render: (linkViewer) => linkViewer.name,
        },
        {
            title: 'Viewer Email',
            dataIndex: 'linkViewer',
            key: 'linkViewer',
            render: (linkViewer) => linkViewer.email,
        },
        {
            title: 'Action',
            render: (record, index) => (
                <span>
                    <a
                        href={`/proposal/${record.proposalLink.linkUuid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Link
                    </a>
                    <Divider type="vertical" />
                    <a href={`/proposals/${record.proposalLink.proposal.id}`}>
                        View Proposal
                    </a>
                </span>
            ),
        },
    ]

    return (
        <Card
            extra={
                <Form layout="inline" onSubmit={handleSubmit}>
                    <Form.Item label={`View Date`}>
                        <RangePicker onChange={onDateRangeChange} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={loading}
                            loading={formData.downloading}
                            onClick={() => {
                                onDownload()
                            }}
                        >
                            Download
                            <Icon type="download" />
                        </Button>
                    </Form.Item>
                </Form>
            }
            bordered={false}
        >
            <Table
                columns={columns}
                loading={loading}
                pagination={curr_pagination}
                onChange={(state) => pageChangeHandler(state)}
                // expandedRowRender={(record) => expandedRowRender(record)}
                dataSource={link_views}
            />
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.proposals,
    }
}

export default connect(mapStateToProps, { requestProposalLinkViews })(
    ReportProposalLinkVIews
)
