import { Card, Col, Row, Space, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { SegmentType } from '.'
const { Title } = Typography

const VaultCard = ({ position, initValues, handleSetVaults, handleRemove }) => {
    let values = { ...initValues }
    delete values.type
    return (
        <>
            <Col span={1}></Col>
            <Col span={10}>
                <Card
                    style={{
                        width: '100%',
                    }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Row>
                            <Col span={20}>
                                <Title level={5}>Vault</Title>
                            </Col>
                            <Col span={4}>
                                <CloseCircleOutlined
                                    style={{ fontSize: '18px', color: 'red' }}
                                    onClick={() =>
                                        handleRemove(
                                            SegmentType.vault,
                                            position
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="number"
                                        name="startingBalance"
                                        onChange={(e) => {
                                            handleSetVaults(
                                                position,
                                                'startingBalance',
                                                e.target.value
                                            )
                                        }}
                                        value={values.startingBalance}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Starting balance
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="number"
                                        name="growthRate"
                                        onChange={(e) => {
                                            handleSetVaults(
                                                position,
                                                'growthRate',
                                                e.target.value
                                            )
                                        }}
                                        value={values.growthRate}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Growth rate
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Space>
                </Card>
            </Col>
        </>
    )
}

export default VaultCard
