import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import RouteButton from '../shared_components/route_button'
import { Form as AntForm, Upload, Table, Switch, Spin } from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'

import { Field } from 'formik'
import BSField from '../shared_components/bs_field'

const AriaV2Form = (props) => {
    const {
        data,
        loading,
        fileLoading,
        error,
        submitHandler,
        buttonText,
        requestAriaV2FileParse,
        requestResetAriaV2,
    } = props
    if (loading) return <div>Loading...</div>
    if (error) return <div>error</div>

    const valSchema = yup.object().shape({
        indexCreditingRate: yup
            .number()
            .typeError('Must be a number')
            .min(0.01, 'Upload file required.')
            .required('Required'),
        initialFaceAmount: yup
            .number()
            .typeError('Must be a number')
            .min(1, 'Must be greater than or equal to 0')
            .max(2147483645, 'Now you are exagerating...')
            .required('Required'),
        qualifiedFundsAmount: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than or equal to 0')
            .max(2147483645, 'Now you are exagerating...')
            .required('Required'),
        incomeStartAge: yup
            .number()
            .typeError('Must be a number')
            .min(data.clientAge, 'Must be greater than or equal to client age')
            .max(100, 'Must be less than or equal to 100')
            .required('Required'),
        federalTaxRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than or equal to 0')
            .max(100, 'Must be less than or equal to 100')
            .test('max', 'Total should not exceed 100 %', function (value) {
                const { stateTaxRate } = this.parent
                return value <= 100 - stateTaxRate
            })
            .required('Required'),
        stateTaxRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than or equal to 0')
            .max(100, 'Must be less than or equal to 100')
            .test('max', 'Total should not exceed 100 %', function (value) {
                const { federalTaxRate } = this.parent
                return value <= 100 - federalTaxRate
            })
            .required('Required'),
        policyData: yup.array().min(1).required('Required'),
    })

    const columns = [
        {
            title: 'Year',
            dataIndex: 'policyYear',
            align: 'right',
        },
        {
            title: 'Age',
            dataIndex: 'beginningOfYearAge',
            align: 'right',
        },
        {
            title: 'Premium',
            dataIndex: 'premiumOutlay',
            align: 'right',
        },
        {
            title: 'Annual Loan',
            dataIndex: 'annualLoanAmount',
            align: 'right',
        },
        {
            title: 'Surrender Value',
            dataIndex: 'nonGuaranteedSurrenderValue',
            align: 'right',
        },
        {
            title: 'Death Benefit',
            dataIndex: 'nonGuaranteedDeathBenefit',
            align: 'right',
        },
    ]

    return (
        <>
            <Formik
                validationSchema={valSchema}
                enableReinitialize={true}
                isInitialValid={() =>
                    valSchema.isValid({
                        ...data,
                    })
                }
                initialValues={{
                    ...data,
                }}
                onSubmit={(values) => {
                    submitHandler(values)
                }}
            >
                {({ values, setFieldValue, touched, errors, isValid }) => (
                    <Form>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <BSField
                                    touched={touched.agentName}
                                    error={errors.agentName}
                                >
                                    <label className="col-md-5 control-label">
                                        Agent Name
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="agentName"
                                            className="form-control"
                                            disabled
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.clientName}
                                    error={errors.clientName}
                                >
                                    <label className="col-md-5 control-label">
                                        Client Name
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="clientName"
                                            className="form-control"
                                            disabled
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.clientAge}
                                    error={errors.clientAge}
                                >
                                    <label className="col-md-5 control-label">
                                        Client Age
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="clientAge"
                                            className="form-control"
                                            disabled
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.clientGender}
                                    error={errors.clientGender}
                                >
                                    <label className="col-md-5 control-label">
                                        Client Gender
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="clientGender"
                                            className="form-control"
                                            disabled
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.indexCreditingRate}
                                    error={errors.indexCreditingRate}
                                >
                                    <label className="col-md-5 control-label">
                                        Index Crediting Rate
                                    </label>
                                    <div className="col-md-7">
                                        {data.indexCreditingRate === 0 ? (
                                            <input
                                                className="form-control"
                                                placeholder="Visible after import"
                                                disabled
                                            />
                                        ) : (
                                            <Field
                                                name="indexCreditingRate"
                                                className="form-control"
                                                disabled
                                            />
                                        )}
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.initialFaceAmount}
                                    error={errors.initialFaceAmount}
                                >
                                    <label className="col-md-5 control-label">
                                        Initial Face Amount
                                    </label>
                                    <div className="col-md-7">
                                        {data.initialFaceAmount === 0 ? (
                                            <input
                                                className="form-control"
                                                placeholder="Visible after import"
                                                disabled
                                            />
                                        ) : (
                                            <Field
                                                name="initialFaceAmount"
                                                className="form-control"
                                                disabled
                                            />
                                        )}
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.qualified}
                                    error={errors.qualified}
                                >
                                    <label className="col-md-5 control-label">
                                        Qualified?
                                    </label>
                                    <div
                                        className="col-md-7"
                                        style={{
                                            height: 34,
                                            paddingTop: 5,
                                        }}
                                    >
                                        <Switch
                                            className="form-control"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={values.qualified}
                                            onChange={() =>
                                                setFieldValue(
                                                    'qualified',
                                                    !values.qualified
                                                )
                                            }
                                        />
                                    </div>
                                </BSField>
                                {values.qualified && (
                                    <BSField
                                        touched={touched.qualifiedFundsAmount}
                                        error={errors.qualifiedFundsAmount}
                                    >
                                        <label className="col-md-5 control-label">
                                            Qualified Funds Amount
                                        </label>
                                        <div className="col-md-7">
                                            <Field
                                                name="qualifiedFundsAmount"
                                                className="form-control"
                                            />
                                        </div>
                                    </BSField>
                                )}
                                <BSField
                                    touched={touched.incomeStartAge}
                                    error={errors.incomeStartAge}
                                >
                                    <label className="col-md-5 control-label">
                                        Income Start Age
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="incomeStartAge"
                                            className="form-control"
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.federalTaxRate}
                                    error={errors.federalTaxRate}
                                >
                                    <label className="col-md-5 control-label">
                                        Federal Tax Rate
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="federalTaxRate"
                                            className="form-control"
                                        />
                                    </div>
                                </BSField>
                                <BSField
                                    touched={touched.stateTaxRate}
                                    error={errors.stateTaxRate}
                                >
                                    <label className="col-md-5 control-label">
                                        State Tax Rate
                                    </label>
                                    <div className="col-md-7">
                                        <Field
                                            name="stateTaxRate"
                                            className="form-control"
                                        />
                                    </div>
                                </BSField>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                {fileLoading ? (
                                    <Spin size="large" />
                                ) : data.policyData.length === 0 ? (
                                    <AntForm.Item>
                                        <div
                                            className="dropbox"
                                            style={{ height: 300 }}
                                        >
                                            <Upload.Dragger
                                                name="files"
                                                beforeUpload={(file) => {
                                                    requestAriaV2FileParse(
                                                        [file],
                                                        values
                                                    )
                                                    return false
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this
                                                    area to upload
                                                </p>
                                            </Upload.Dragger>
                                        </div>
                                    </AntForm.Item>
                                ) : (
                                    <>
                                        <Table
                                            rowKey="policyYear"
                                            dataSource={data.policyData}
                                            columns={columns}
                                        />
                                        {!buttonText && (
                                            <button
                                                onClick={() =>
                                                    requestResetAriaV2(values)
                                                }
                                                className="btn btn-danger pull-left"
                                                style={{ marginTop: '-46px' }}
                                            >
                                                Reset Import
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-md-12 form-group">
                                <RouteButton
                                    path="/aria-v2"
                                    name="Cancel"
                                    className="btn btn-danger pull-left"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary pull-right"
                                    disabled={!isValid}
                                >
                                    {buttonText || 'Create Proposal'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AriaV2Form
