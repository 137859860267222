import { toast } from 'react-toastify'
import { all, call, fork, put, take } from 'redux-saga/effects'

import {
    ALERT_ERROR,
    ALERT_SUCCESS,
    ARBITRAGE_GET_REQUEST,
    errorAlert,
    failedArbitrage,
    failedIndexes,
    failedStates,
    INDEXES_GET_REQUEST,
    LOGOUT_REQUEST,
    PROCESS_ERROR,
    processErrorMessage,
    STATES_GET_REQUEST,
    successArbitrage,
    successIndexes,
    successStates,
} from '../actions'
import { commonService } from '../services/common.service'
import {
    createAgency,
    createAgencyAgent,
    getAgencies,
    getAgenciesAgents,
    getAgency,
    getAgencyAgents,
    updateAgency,
} from './agency.saga'
import {
    createBucketReport,
    getBucketReports,
    getBucketReport,
    updateBucketReport,
    getBucketReportSegments,
} from './bucket-report.saga'
import {
    createAgent,
    getAgent,
    getAgentClients,
    getAgentProposals,
    getAgents,
    updateAgent,
} from './agent.saga'
import {
    createClient,
    getClient,
    getClients,
    updateClient,
} from './client.saga'
import {
    createCalcClient,
    getCalcClient,
    getCalcClients,
    updateCalcClient,
} from './calcClient.saga'
import {
    createIllustration,
    getClientIllustrations,
    getIllustration,
    getIllustrations,
    updateIllustration,
    uploadFileParse,
} from './illustration.saga'
import {
    resetIncomeComp,
    createIncomeComp,
    getIncomeComp,
    getIncomeComps,
    updateIncomeComp,
    updateIncomeCompTaxCalc,
    setIncomeCompClient,
} from './incomeComp.saga'
import {
    addProposal,
    createProposal,
    getClientProposals,
    getDetailProposal,
    getProposal,
    getProposalLinkEmails,
    getProposalLinks,
    getProposalLinkViews,
    getProposals,
    sendProposalLinkByEmail,
    updateProposal,
} from './proposal.saga'
import {
    addAriaV2,
    createAriaV2,
    getClientAriaV2s,
    getAriaV2,
    getAriaV2PDFs,
    getAriaV2StaticPDFs,
    getAriaV2s,
    // resetAriaV2,
    updateAriaV2,
    uploadAriaV2FileParse,
    resetAriaV2FileParse,
} from './ariav2.saga'

function* processError() {
    while (true) {
        const { errorMessage } = yield take(PROCESS_ERROR)
        switch (errorMessage) {
            case 'Signature has expired.':
                yield put({ type: LOGOUT_REQUEST })
                break
            default:
                yield put(errorAlert(errorMessage))
        }
    }
}

function* getStates() {
    while (true) {
        yield take(STATES_GET_REQUEST)
        try {
            const response = yield call(commonService.getStates)
            yield put(successStates(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedStates(error))
        }
    }
}

function* getIndexes() {
    while (true) {
        yield take(INDEXES_GET_REQUEST)
        try {
            const response = yield call(commonService.getIndexes)
            yield put(successIndexes(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedIndexes(error))
        }
    }
}

function* getArbitrage() {
    while (true) {
        yield take(ARBITRAGE_GET_REQUEST)
        try {
            const response = yield call(commonService.getArbitrage)
            yield put(successArbitrage(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedArbitrage(error))
        }
    }
}

function* displayAlerts() {
    while (true) {
        const { alert, message } = yield take([ALERT_SUCCESS, ALERT_ERROR])
        switch (alert) {
            case 'success':
                toast.success(message)
                break
            case 'error':
                toast.error(message)
                break
            default:
                toast.info(message)
        }
    }
}

export default function* sagas() {
    yield all([
        fork(getProposals),
        fork(getClientProposals),
        fork(getClientIllustrations),
        fork(getProposal),
        fork(getDetailProposal),
        fork(createProposal),
        fork(addProposal),
        fork(updateProposal),
        fork(sendProposalLinkByEmail),
        fork(getIllustrations),
        fork(getAriaV2StaticPDFs),
        fork(getProposalLinks),
        fork(getProposalLinkViews),
        fork(getProposalLinkEmails),
        fork(addAriaV2),
        fork(createAriaV2),
        fork(getClientAriaV2s),
        fork(getAriaV2),
        // fork(resetAriaV2),
        fork(getAriaV2PDFs),
        fork(getAriaV2s),
        fork(updateAriaV2),
        fork(uploadAriaV2FileParse),
        fork(resetAriaV2FileParse),
        fork(getIllustration),
        fork(uploadFileParse),
        fork(createIllustration),
        fork(updateIllustration),
        fork(getClients),
        fork(getClient),
        fork(updateClient),
        fork(createClient),
        fork(getCalcClients),
        fork(getCalcClient),
        fork(updateCalcClient),
        fork(createCalcClient),
        fork(getIncomeComps),
        fork(getIncomeComp),
        fork(resetIncomeComp),
        fork(updateIncomeComp),
        fork(updateIncomeCompTaxCalc),
        fork(createIncomeComp),
        fork(setIncomeCompClient),
        fork(getAgencies),
        fork(getAgency),
        fork(getAgencyAgents),
        fork(getAgenciesAgents),
        fork(updateAgency),
        fork(createAgency),
        fork(createAgencyAgent),
        fork(getAgent),
        fork(getAgents),
        fork(getAgentClients),
        fork(getAgentProposals),
        fork(updateAgent),
        fork(createAgent),
        fork(getStates),
        fork(getIndexes),
        fork(getArbitrage),
        fork(displayAlerts),
        fork(processError),

        fork(createBucketReport),
        fork(getBucketReports),
        fork(getBucketReport),
        fork(updateBucketReport),
        fork(getBucketReportSegments),
    ])
}
