import { Button, Popover, Table } from 'antd'
import Icon from '@ant-design/icons/lib/components/Icon'

import moment from 'moment'
import React, { Component } from 'react'
import IPAddressDetail from '../../shared_components/ip_address_details'

const columns = [
    {
        title: 'Agent',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (createdBy) => (
            <Button type="link">{createdBy.username}</Button>
        ),
    },
    {
        title: 'Send Date',
        dataIndex: 'sendDate',
        key: 'sendDate',
        render: (sendDate) => moment(sendDate).format('MM/DD/YYYY'),
    },
    { title: 'Send To', dataIndex: 'email', key: 'email' },
    {
        title: 'Action',
        render: (record, index) => (
            <a
                href={`/proposal/${record.linkUuid}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon type="eye" />
            </a>
        ),
    },
]

class ProposalLinks extends Component {
    expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'linkViewer',
                key: 'linkViewer',
                render: (linkViewer) => linkViewer.name,
            },
            {
                title: 'Email',
                dataIndex: 'linkViewer',
                key: 'linkViewer',
                render: (linkViewer) => linkViewer.email,
            },
            {
                title: 'IP Address',
                dataIndex: 'ipaddress',
                key: 'ipaddress',
                render: (ipaddress) => (
                    <Popover
                        content={<IPAddressDetail {...ipaddress} />}
                        title={ipaddress.ipaddress}
                    >
                        <Button type="link">{ipaddress.ipaddress}</Button>
                    </Popover>
                ),
            },
            {
                title: 'View Date',
                dataIndex: 'viewDate',
                key: 'viewDate',
                render: (viewDate) => moment(viewDate).format('MM/DD/YYYY'),
            },
        ]

        return (
            <Table
                size="small"
                bordered={false}
                columns={columns}
                dataSource={record.linkViews}
                pagination={false}
            />
        )
    }

    render() {
        const { links, loading } = this.props

        return (
            <Table
                size={'small'}
                columns={columns}
                loading={loading}
                pagination={{
                    position: 'bottom',
                    defaultPageSize: 4,
                }}
                expandedRowRender={(record) => this.expandedRowRender(record)}
                dataSource={links}
            />
        )
    }
}

export default ProposalLinks
