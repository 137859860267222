import { isEmpty } from 'lodash'
import { BucketType, BucketDetailType } from '..'

import * as api from './api'

export const bucketReportService = {
    createBucketReport,
    getAllBucketReports,
    getBucketReportSegments,
    updateBucketReport,
}

async function getAllBucketReports(clientId: number): Promise<any | undefined> {
    try {
        const response = await api.get(
            `/api/v1/bucket-reports/client/${clientId}`
        )
        return response.data as any
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucketReportSegments(
    reportId: number
): Promise<any | undefined> {
    try {
        const response = await api.get(
            `/api/v1/bucket-reports/segments/${reportId}`
        )
        return response.data as any
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucket(bucketId: string): Promise<BucketType | undefined> {
    try {
        const response = await api.get(`/api/v1/buckets/${bucketId}/`)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function createBucketReport(payload: any): Promise<any | undefined> {
    try {
        const response = await api.post('/api/v1/bucket-reports/', payload)
        return response.data as any
    } catch (error) {
        Promise.reject(error)
    }
}

async function updateBucketReport(
    bucketReportId: number | string,
    payload: any
): Promise<any | undefined> {
    try {
        console.log('bucket report id: ', bucketReportId)
        console.log('payload is: ', payload)
        const response = await api.put(
            `/api/v1/bucket-reports/${bucketReportId}/`,
            payload
        )
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function cloneBucket(
    bucketId: number | string
): Promise<BucketType | undefined> {
    try {
        const response = await api.post(`/api/v1/buckets/${bucketId}/`)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function updateBucket(
    bucketId: number | string,
    payload: BucketType
): Promise<BucketType | undefined> {
    try {
        const response = await api.put(`/api/v1/buckets/${bucketId}/`, payload)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucketDetails(
    bucketId: number | string
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.get(
            `/api/v1/buckets/${bucketId}/bucket-details/`
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function createBucketDetail(
    bucketId: number | string,
    payload: BucketDetailType
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.post(
            `/api/v1/buckets/${bucketId}/bucket-details/`,
            {
                ...payload,
            }
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string
): Promise<BucketDetailType | undefined> {
    try {
        const response = await api.get(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`
        )
        return response.data as BucketDetailType
    } catch (error) {
        Promise.reject(error)
    }
}
async function updateBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string,
    payload: BucketDetailType
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.put(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`,
            payload
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function deleteBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api._delete(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}
