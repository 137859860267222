import { Button, Col, Input, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { useAuth } from '../../components/use_auth'
import Profile from './shared/Profile'
import AgenciesTabs from './Agency/AgenciesTabs'
import {
    requestAgencies,
    requestCreateAgency,
    requestStates,
    requestUpdateAgency,
    requestCreateAgencyAgent,
    requestUpdateAgent,
    requestCreateClient,
    requestUpdateClient,
} from '../../actions'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import AdvisorsClientsTabs from './Advisor/AdvisorsClientsTabs'
import ProfileForm from './shared/ProfileForm'
const { Search } = Input
const { Title } = Typography

const onSearch = (value: any) => console.log(value)

interface IProfile {
    type: string
    for: string
    id: number
}

export interface IShowProfile {
    for: string
    id: number
}

// TODO: set props type
const ProfileManagement = (props: any) => {
    const auth = useAuth()
    const history = useHistory()
    const params = useParams<{ type: string; id: string }>()
    const { type, id } = params

    const [showProfile, setShowProfile] = useState<IShowProfile | undefined>(
        undefined
    )
    const [showProfileForm, setShowProfileForm] = useState<
        | {
              type: string
              for: String
              id?: number | undefined
              advisorId?: number | undefined
          }
        | undefined
    >(undefined)

    const [showAgencyForm, setShowAgencyForm] = useState(false)
    const [showAdvisorForm, setShowAdvisorForm] = useState(false)

    const [selectedAgency, setSelectedAgency] = useState<{
        id: number
        name: string
    }>()

    useEffect(() => {
        props.requestAgencies(props.page, props.pageSize, props.sorted)
        props.requestStates()
    }, [])

    useEffect(() => {
        if (props.data.length > 0) {
            if (type === 'agency') {
                const agency = props.data.find((agency: any) => agency.id == id)
                handleSelectedAgency(agency)
            }
        }
    }, [props.data])

    function handleSelectedAgency(values: any) {
        setShowProfileForm(undefined)
        setSelectedAgency(values)
        setShowProfile({ id: values.id, for: 'agency' })
    }

    function handleSetShowProfile(values: any) {
        setShowProfileForm(undefined)
        setSelectedAgency(selectedAgency)
        setShowProfile(values)
    }

    function cancelShowProfileForm(values: any) {
        setShowProfileForm(undefined)
        setShowProfile(values)
    }

    function handleShowProfileForm(values: IProfile) {
        if (values.type == 'edit') {
            setShowProfile(undefined)
            setShowProfileForm(values)
        } else if (values.type == 'new') {
            setShowProfile(undefined)
            setShowProfileForm(values)
        } else {
            setShowProfile(values)
            setShowProfileForm(undefined)
        }
    }

    function handleProfileFormSubmit(values: any) {
        if (showProfileForm) {
            if (showProfileForm.for == 'agency') {
                if (showProfileForm.type == 'edit') {
                    props.requestUpdateAgency(showProfileForm.id, {
                        ...values,
                    })
                } else {
                    props.requestCreateAgency({ ...values, history })
                }
                setShowProfile(undefined)
                setShowProfileForm(undefined)
                handleSelectedAgency(selectedAgency)
            } else if (showProfileForm.for == 'advisor') {
                if (showProfileForm.type == 'edit') {
                    props.requestUpdateAgent(showProfileForm.id, {
                        ...values,
                        handleShowProfileForm,
                    })
                } else {
                    props.requestCreateAgencyAgent(selectedAgency!.id, {
                        ...values,
                    })
                }
                setShowProfile(undefined)
                setShowProfileForm(undefined)
                handleSelectedAgency(selectedAgency)
            } else if (showProfileForm.for == 'client') {
                if (showProfileForm.type == 'edit') {
                    props.requestUpdateClient(showProfileForm.id, {
                        ...values,
                    })
                } else {
                    props.requestCreateClient({ ...values, history })
                }

                setShowProfileForm(undefined)
                setSelectedAgency(selectedAgency)
                setShowProfile({
                    for: 'advisor',
                    id: showProfileForm.advisorId!,
                })
            }
        }
    }

    return (
        <>
            <Row>
                <Col span={1}></Col>
                <Col span={4}>
                    <Title level={4} style={{ margin: 0 }}>
                        Profile management
                    </Title>
                </Col>

                <Col span={8}>
                    <Search
                        placeholder="Agency, advisor, or client"
                        onSearch={onSearch}
                        enterButton
                    />
                </Col>
                <Col span={6}>
                    <Button
                        type="primary"
                        size={'middle'}
                        onClick={() => {
                            setSelectedAgency(undefined)
                            setShowProfile(undefined)
                            setShowProfileForm({
                                for: 'agency',
                                type: 'new',
                            })
                        }}
                    >
                        Add agency
                    </Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={1}></Col>
                <Col span={4}>
                    <AgenciesTabs
                        selectedAgency={selectedAgency}
                        setSelectedAgency={handleSelectedAgency}
                        {...props}
                    />
                </Col>
                <Col span={4}>
                    {selectedAgency && (
                        <AdvisorsClientsTabs
                            selectedAgency={selectedAgency}
                            setShowProfile={handleSetShowProfile}
                            setShowAdvisorForm={setShowAdvisorForm}
                            {...props}
                        />
                    )}
                </Col>
                <Col
                    span={14}
                    style={
                        showProfile || showProfileForm
                            ? {
                                  border: '2px solid #E0E0E0',
                                  borderRadius: 5,
                                  padding: 20,
                              }
                            : {}
                    }
                >
                    {showProfileForm && (
                        <ProfileForm
                            showProfileForm={showProfileForm}
                            handleProfileFormSubmit={handleProfileFormSubmit}
                            cancelShowProfileForm={cancelShowProfileForm}
                            selectedAgency={selectedAgency}
                            {...props}
                        />
                    )}

                    {showProfile && (
                        <Profile
                            showProfile={showProfile}
                            selectedAgency={selectedAgency}
                            setShowProfile={setShowProfile}
                            setShowProfileForm={handleShowProfileForm}
                            {...props}
                        />
                    )}
                </Col>

                <Col span={1}></Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: { agencies: any; states: any }) => {
    return {
        ...state.agencies,
        states: state.states,
    }
}

export default connect(mapStateToProps, {
    requestAgencies,
    requestCreateAgency,
    requestUpdateAgency,
    requestCreateAgencyAgent,
    requestStates,
    requestUpdateAgent,
    requestCreateClient,
    requestUpdateClient,
})(ProfileManagement)
