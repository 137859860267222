import 'react-toastify/dist/ReactToastify.min.css'

import { Layout, Menu } from 'antd'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import Icon from '@ant-design/icons/lib/components/Icon'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import NoMatch from '../components/no_match'
import AgenciesPage from './AgenciesPage'
import AgencyAddPage from './AgencyAddPage'
import AgencyEditPage from './AgencyEditPage'
import AgencyViewPage from './AgencyViewPage'
import AgentAddPage from './AgentAddPage'
import AgentEditPage from './AgentEditPage'
import AgentViewPage from './AgentViewPage'
import ArbitragePage from './ArbitragePage'
import BizPlanPage from './BizPlanPage'
import CashflowPage from './CashflowPage'
import ChangePassword from './ChangePassword'
import ClientAddPage from './ClientAddPage'
import ClientEditPage from './ClientEditPage'
import ClientsPage from './ClientsPage'
import ClientViewPage from './ClientViewPage'
import Dashboard from './Dashboard'
import DashboardPage from './DashboardPage'
import IllustrationAddPage from './IllustrationAddPage'
import IllustrationEditPage from './IllustrationEditPage'
import IllustrationsPage from './IllustrationsPage'
import IllustrationViewPage from './IllustrationViewPage'
import IncomeAddPage from './IncomeAddPage'
import IncomePage from './IncomePage'
import IncomeViewPage from './IncomeViewPage'
import LoginPage from './LoginPage'
import PreQualInsurancePage from './prequalifyinsurance'
import ProposalAddPage from './ProposalAddPage'
import ProposalEditPage from './ProposalEditPage'
import ProposalsPage from './ProposalsPage'
import ProposalViewPage from './ProposalViewPage'
import ProposalViewPDFPage from './ProposalViewPDFPage'
import AriaV2AddPage from './AriaV2AddPage'
import AriaV2EditPage from './AriaV2EditPage'
import AriaV2sPage from './AriaV2sPage'
import AriaV2ViewPage from './AriaV2ViewPage'
import AriaV2ViewPDFPage from './AriaV2ViewPDFPage'
import ReportsPage from './reports'
import VolatilityPage from './volatility'
import VolatilityLostPage from './volatility/lost'
import BucketPage from './BucketPage'
import BucketsPage from './BucketsPage'
import { CALC, AGENCY, STAFF_USER, REPORT, TRAINING } from '../constants'
import PrivateRoute from '../components/private_route'
import { useAuth } from '../components/use_auth'
import { REACT_APP_VERSION } from '../config/env'
import FullPageLoading from '../components/shared_components/full_page_loading'
import { useServiceWorker } from '../hooks/useServiceWorker'
import { toast } from 'react-toastify'
import ProfileManagement from '../components/ProfileManagement'
import BucketTool from '../components/BucketTool'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

function MainComponent() {
    const auth = useAuth()
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const toastId = React.useRef(null)

    useEffect(() => {
        if (showReload && waitingWorker) {
            toastId.current = toast.info(
                <div>
                    A new version of this page is available
                    <button onClick={() => reloadPage()}>REFRESH</button>
                </div>,
                {
                    autoClose: false,
                    position: 'bottom-right',
                    closeOnClick: false,
                }
            )
        } else {
            if (toastId.current) {
                toast.dismiss(toastId.current)
            }
        }
    }, [waitingWorker, showReload, reloadPage])

    // TODO: use the jwt token to transport the roles, this can be subverted in the UI and is a security hazard
    const nav = [
        {
            text: 'Dashboard',
            icon: 'dashboard',
            ref: '/dashboard',
            role: AGENCY,
            type: 'menu',
        },

        {
            text: 'Reports',
            icon: 'unordered-list',
            ref: '/reports/links',
            role: STAFF_USER,
            hidden: true,
            type: 'menu',
        },
        {
            text: 'Reports',
            icon: 'unordered-list',
            ref: '/reports/link_views',
            role: STAFF_USER,
            type: 'menu',
        },
        {
            text: 'Cash Flow Analyzer',
            icon: 'dollar',
            ref: '/cashflow',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Volatility (Lost)',
            icon: 'line-chart',
            ref: '/volatility-lost',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Volatility',
            icon: 'line-chart',
            ref: '/volatility',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Pre-Qualify Insurance',
            icon: 'calculator',
            ref: '/pre-qual-insurance',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Arbitrage',
            icon: 'calculator',
            ref: '/arbitrage',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Income',
            icon: 'build',
            ref: '/income-comps',
            role: CALC,
            type: 'menu',
        },
        {
            text: 'Training',
            icon: 'read',
            external: true,
            type: 'menu',
            ref: 'http://peakprofinancial-6126024.hs-sites.com/training-home-peak-pro-financial',
            role: TRAINING,
        },
        {
            text: auth.username || 'Account',
            icon: null,
            type: 'submenu',
            role: null,
            items: [
                {
                    text: 'Change Password',
                    icon: null,
                    ref: '/change-password',
                    type: 'menu',
                },
                {
                    text: 'Logout',
                    icon: 'logout',
                    onclick: auth.logout,
                    ref: null,
                    type: 'menu',
                },
            ],
        },
    ]

    const default_menu = nav[0] || { text: '' }
    const selected_menu =
        nav.find((m) => m.ref === window.location.pathname) || default_menu

    const externalLink = (item, index) => {
        return (
            <a
                className={'ant-menu-item'}
                key={index}
                target="_blank"
                href={item.ref}
                rel="noopener noreferrer"
            >
                {item.icon && <Icon type={item.icon} />}
                <span style={{ paddingLeft: 5 }}>{item.text}</span>
            </a>
        )
    }

    const menuItem = (item, idx) => {
        if (item.onclick) {
            return (
                <Menu.Item onClick={item.onclick} key={idx}>
                    {item.icon && <Icon type={item.icon} />}
                    <span style={{ paddingLeft: 5 }}>{item.text}</span>
                </Menu.Item>
            )
        } else {
            return (
                <Menu.Item key={idx}>
                    <Link to={item.ref}>
                        {item.icon && <Icon type={item.icon} />}
                        <span style={{ paddingLeft: 5 }}>{item.text}</span>
                    </Link>
                </Menu.Item>
            )
        }
    }

    const subMenu = (item, idx) => {
        return (
            <SubMenu
                style={item.style}
                key={idx}
                title={
                    <span>
                        {item.icon && <Icon type={item.icon} />}{' '}
                        <span>{item.text}</span>
                    </span>
                }
            >
                {item.items.map((subItem, subIndex) => {
                    return menuItem(subItem, `${idx}.${subIndex}`)
                })}
            </SubMenu>
        )
    }

    return (
        <Layout>
            <FullPageLoading />

            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <div style={{ float: 'left' }}>
                    <Link to="/">
                        <img
                            src="/static/images/logo2b6-122x40.gif"
                            alt="PeakPro"
                        />
                    </Link>
                </div>
                {auth.loggedIn && (
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={[selected_menu.text]}
                        selectedKeys={[selected_menu.text]}
                        style={{ lineHeight: '64px' }}
                    >
                        {nav.map((item, idx) => {
                            let menuEntry
                            if (
                                (auth.roles.includes(item.role) ||
                                    item.role === null) &&
                                !item.hidden
                            ) {
                                if (item.type === 'submenu') {
                                    menuEntry = subMenu(item, idx)
                                } else {
                                    if (item.external) {
                                        menuEntry = externalLink(item, idx)
                                    } else {
                                        menuEntry = menuItem(item, idx)
                                    }
                                }
                            }
                            return menuEntry
                        })}
                    </Menu>
                )}
            </Header>
            <Content style={{ padding: '0 50px', marginTop: 64 }}>
                <div
                    style={{
                        background: '#fff',
                        marginTop: 10,
                        padding: 24,
                        minHeight: 380,
                    }}
                >
                    <ToastContainer />
                    <Sentry.ErrorBoundary
                        fallback={({ error, componentStack, resetError }) => (
                            <PrivateRoute userRole={null}>
                                <div className="h1">
                                    Uh oh! Something did not go as planned!
                                </div>
                                <div className="h3">
                                    But don't worry, we are working on it!
                                </div>
                                <div>{error.toString()}</div>
                                <div>{componentStack}</div>
                                <button
                                    onClick={() => {
                                        resetError()
                                    }}
                                >
                                    Click here to reset!
                                </button>
                            </PrivateRoute>
                        )}
                    >
                        <Switch>
                            <Route
                                path="/login"
                                exact
                                children={<LoginPage />}
                            />
                            <Route
                                path="/"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/dashboard">
                                            <DashboardPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/dashboard"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/dashboard">
                                            <DashboardPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/proposals">
                                            <ProposalsPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals/add/:illustrationId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ProposalAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals/add"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ProposalAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals/edit/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ProposalEditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals/pdf/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ProposalViewPDFPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/proposals/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ProposalViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/aria-v2"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/aria-v2">
                                            <AriaV2sPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/aria-v2/add"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AriaV2AddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/aria-v2/edit/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AriaV2EditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/aria-v2/pdf/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AriaV2ViewPDFPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/aria-v2/:proposalId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AriaV2ViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/buckets"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/buckets">
                                            <BucketsPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/buckets/:bucketId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <BucketPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/illustrations"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/illustrations">
                                            <IllustrationsPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/illustrations/add"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <IllustrationAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/illustrations/add/:clientId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <IllustrationAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/illustrations/edit/:illustrationId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <IllustrationEditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/illustrations/:illustrationId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <IllustrationViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/clients"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/clients">
                                            <ClientsPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/clients/add"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ClientAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/clients/edit/:clientId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ClientEditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/clients/:clientId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <ClientViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/agencies">
                                            <AgenciesPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies/add"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgencyAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies/edit/:agencyId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgencyEditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies/:agencyId/agents/add"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgentAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path={
                                    '/agencies/:agencyId/agents/edit/:agentId'
                                }
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgentEditPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies/:agencyId/agents/:agentId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgentViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/agencies/:agencyId"
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <AgencyViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <CashflowPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/arbitrage"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <ArbitragePage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/biz-plan"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <BizPlanPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/Cashflow"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <CashflowPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/pre-qual-insurance"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <PreQualInsurancePage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/volatility-lost"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <VolatilityLostPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/volatility"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <VolatilityPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/income-comps"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <IncomePage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/income-comps/add"
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <IncomeAddPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/income-comps/:incomeCompId"
                                render={() => (
                                    <PrivateRoute userRole={CALC}>
                                        <IncomeViewPage />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/reports/links"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={REPORT}>
                                        <ReportsPage active="/reports/links" />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/reports/link_views"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={REPORT}>
                                        <ReportsPage active="/reports/link_views" />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/change-password"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={null}>
                                        <ChangePassword />
                                    </PrivateRoute>
                                )}
                            />{' '}
                            <Route
                                path="/profiles"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/profiles">
                                            <ProfileManagement />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/profiles/:type/:id"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/profiles">
                                            <ProfileManagement />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/bucket-tool/client/:clientId"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <BucketTool active="/bucket-tool" />
                                    </PrivateRoute>
                                )}
                            />
                            <Route
                                path="/bucket-tool/client/:clientId/report/:bucketId"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <BucketTool active="/bucket-tool" />
                                    </PrivateRoute>
                                )}
                            />
                            <Route children={NoMatch} />
                        </Switch>
                    </Sentry.ErrorBoundary>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                &copy; 2022 PeakPro Financial - All Rights Reserved - version{' '}
                {REACT_APP_VERSION}
            </Footer>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

const Main = connect(mapStateToProps)(MainComponent)
export default Main
