/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 15 2019
 */
import React from 'react'
import { Button, Form, InputNumber } from 'antd'
import { connect } from 'react-redux'
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field])
}

class VolatilityForm extends React.Component {
    componentDidMount() {
        this.props.form.validateFields()
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            values['gross_income'] = 0

            if (this.props.onSubmit !== undefined) this.props.onSubmit(values)
        })
    }

    onTaxRateChange = (value) => {
        console.log('changed', value)
    }

    render() {
        const {
            getFieldDecorator,
            getFieldsError,
            getFieldError,
            isFieldTouched,
        } = this.props.form

        const startingError =
            isFieldTouched('starting') && getFieldError('starting')
        const net_incomeError =
            isFieldTouched('net_income') && getFieldError('net_income')
        const tax_rateError =
            isFieldTouched('tax_rate') && getFieldError('tax_rate')

        return (
            <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item
                    label={`Starting Value`}
                    validateStatus={startingError ? 'error' : ''}
                    help={startingError || ''}
                >
                    {getFieldDecorator('starting', {
                        initialValue: 300000,
                        rules: [
                            {
                                required: true,
                                message: 'Please input a starting value!',
                            },
                        ],
                    })(
                        <InputNumber
                            placeholder="Net Income"
                            style={{ width: 120 }}
                            formatter={(value) =>
                                `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={`Net Income`}
                    validateStatus={net_incomeError ? 'error' : ''}
                    help={net_incomeError || ''}
                >
                    {getFieldDecorator('net_income', {
                        initialValue: 38000,
                        rules: [
                            {
                                required: true,
                                message: 'Please input the net income!',
                            },
                        ],
                    })(
                        <InputNumber
                            placeholder="Net Income"
                            style={{ width: 120 }}
                            formatter={(value) =>
                                `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={`Tax Rate`}
                    validateStatus={tax_rateError ? 'error' : ''}
                    help={tax_rateError || ''}
                >
                    {getFieldDecorator('tax_rate', {
                        initialValue: 30,
                        rules: [
                            {
                                required: true,
                                message: 'Please input the tax rate!',
                            },
                        ],
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                            onChange={this.onTaxRateChange.bind(this)}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        Calculate
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

// const WrappedVolatilityForm = Form.create({ name: 'horizontal_login' })(
//     VolatilityForm
// )

// export default WrappedVolatilityForm

const mapStateToProps = (state) => {
    return {
        name: 'horizontal_login',
        loading: state.loading,
        error: state.error,
    }
}

export default connect(mapStateToProps, {})(VolatilityForm)
