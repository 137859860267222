import React, { useState } from 'react'
import {
    Select,
    Row,
    List,
    Spin,
    Card,
    Button,
    Col,
    Divider,
    Pagination,
} from 'antd'
import { Link } from 'react-router-dom'
import * as api from '../../services/api'
import { useQuery } from 'react-query'
import { Agency, Agent, Paged } from '../..'

const { Option } = Select

const AgencyWidget = () => {
    const [selectedAgency, setSelectedAgency] = useState()
    const [page, setPage] = useState(1)

    const agencies = useQuery<Agency[]>(['agencies'], () =>
        api.get('/api/v1/agencies/').then((res) => res.data.data)
    )
    const agents = useQuery<Paged<Agent>>(
        ['agencies', selectedAgency, 'agents', page],
        () =>
            api
                .get(`/api/v1/agencies/${selectedAgency}/agents/`, {
                    params: { page },
                })
                .then((res) => res.data),
        { enabled: !!selectedAgency, keepPreviousData: true }
    )

    return (
        <Card
            title="Agencies"
            style={{ minHeight: 300 }}
            extra={
                <Row>
                    <Link to={'/agencies/add'}>
                        <Button
                            type="default"
                            shape="round"
                            icon="plus"
                        ></Button>
                    </Link>
                    <Divider type="vertical" />
                    {agencies.isLoading ? (
                        <Spin />
                    ) : (
                        <Button
                            type="default"
                            shape="round"
                            icon="reload"
                            onClick={() => agencies.refetch()}
                        />
                    )}
                </Row>
            }
        >
            <Row>
                <Select
                    showSearch
                    loading={agencies.isLoading}
                    style={{ width: '100%' }}
                    placeholder="Select or search an agent"
                    optionFilterProp="children"
                    onChange={setSelectedAgency}
                    filterOption={(input, option) =>
                        option
                            ? option.props.children
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                            : false
                    }
                >
                    {agencies.data &&
                        agencies.data.map((agency) => {
                            return (
                                <Option key={agency.id.toString()}>
                                    {agency.company}
                                </Option>
                            )
                        })}
                </Select>
            </Row>
            <Row style={{ height: 20 }}>
                <Col
                    style={{
                        textAlign: 'center',
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    {agents.isLoading && <Spin tip="Loading..." />}
                </Col>
            </Row>
            <Row>
                {agents.data && selectedAgency && (
                    <>
                        <List
                            loading={agents.isLoading}
                            size="small"
                            dataSource={agents.data.data}
                            renderItem={(agent) => (
                                <List.Item
                                    actions={[
                                        <Link
                                            to={`/agencies/${selectedAgency}/agents/${agent.id}`}
                                        >
                                            View
                                        </Link>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={
                                            <Link
                                                to={`/agencies/${selectedAgency}/agents/${agent.id}`}
                                            >
                                                {agent.fullName}
                                            </Link>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p>
                                Showing{' '}
                                {Math.min(
                                    agents.data.perPage *
                                        (agents.data.page + 1),
                                    agents.data.count
                                )}{' '}
                                of {agents.data.count}
                            </p>
                            <Pagination
                                current={agents.data.page + 1}
                                onChange={setPage}
                                pageSize={agents.data.perPage}
                                pageSizeOptions={['10', '25', '50', '100']}
                                total={agents.data.count}
                            />
                        </div>
                    </>
                )}
            </Row>
        </Card>
    )
}
export default AgencyWidget
