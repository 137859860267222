import { combineReducers } from 'redux'

import agencies from './agencies.reducer'
import agenciesAgents from './agenciesAgents.reducer'
import agency from './agency.reducer'
import agent from './agent.reducer'
import agents from './agents.reducer'
import alerts from './alert.reducer'
import arbitrage from './arbitrage.reducer'
import client from './client.reducer'
import clients from './clients.reducer'
import calcClient from './calcClient.reducer'
import calcClients from './calcClients.reducer'
import illustration from './illustration.reducer'
import illustrations from './illustrations.reducer'
import incomeComp from './incomeComp.reducer'
import incomeComps from './incomeComps.reducer'
import indexes from './indexes.reducer'
import proposal from './proposal.reducer'
import proposals from './proposals.reducer'
import ariaV2StaticPDFs from './ariav2_static_pdfs.reducer'
import ariav2s from './ariav2s.reducer'
import ariav2 from './ariav2.reducer'
import states from './states.reducer'
import taxCalcStatus from './taxCalcStatus.reducer'
import bucketReports from './bucketReports.reducer'
import bucketReportSegments from './bucketReportSegments.reducer'

const rootReducer = combineReducers({
    alerts,
    ariav2,
    ariav2s,
    proposals,
    proposal,
    ariaV2StaticPDFs,
    illustrations,
    illustration,
    clients,
    client,
    calcClients,
    calcClient,
    incomeComp,
    taxCalcStatus,
    incomeComps,
    agencies,
    agenciesAgents,
    agency,
    agents,
    agent,
    indexes,
    states,
    arbitrage,
    bucketReports,
    bucketReportSegments,
})

export default rootReducer
