import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { bucketReportService } from '../services/bucket-report.service'

const {
    BUCKET_REPORTS_GET_REQUEST,
    successBucketReports,
    failedBucketReports,
    BUCKET_REPORT_GET_REQUEST,
    successBucketReport,
    failedBucketReport,
    BUCKET_REPORT_UPDATE_REQUEST,
    failedUpdateBucketReport,
    BUCKET_REPORT_CREATE_REQUEST,
    failedCreateBucketReport,
    processErrorMessage,
    successAlert,
    BUCKET_REPORT_GET_SEGMENTS_REQUEST,
    successBucketReportSegments,
    failedBucketReportSegments,
} = actions

export function* getBucketReports() {
    while (true) {
        const { clientId } = yield take(BUCKET_REPORTS_GET_REQUEST)
        try {
            const response = yield call(
                bucketReportService.getAllBucketReports,
                clientId
            )
            yield put(successBucketReports(response))
        } catch (error) {
            yield put(failedBucketReports(error))
        }
    }
}

export function* getBucketReportSegments() {
    while (true) {
        const { reportId } = yield take(BUCKET_REPORT_GET_SEGMENTS_REQUEST)
        try {
            const response = yield call(
                bucketReportService.getBucketReportSegments,
                reportId
            )
            yield put(successBucketReportSegments(response))
        } catch (error) {
            yield put(failedBucketReportSegments(error))
        }
    }
}

export function* getBucketReport() {
    while (true) {
        const { bucketReportId } = yield take(BUCKET_REPORT_GET_REQUEST)
        try {
            const response = yield call(
                bucketReportService.getBucketReport,
                bucketReportId
            )
            yield put(successBucketReport(response))
        } catch (error) {
            yield put(failedBucketReport(error))
        }
    }
}

export function* updateBucketReport() {
    while (true) {
        const { bucketReportId, payload } = yield take(
            BUCKET_REPORT_UPDATE_REQUEST
        )
        try {
            yield call(
                bucketReportService.updateBucketReport,
                bucketReportId,
                payload
            )
            yield put(successAlert('BucketReport updated'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateBucketReport(error))
        }
    }
}

export function* createBucketReport() {
    while (true) {
        const { payload } = yield take(BUCKET_REPORT_CREATE_REQUEST)
        try {
            yield call(bucketReportService.createBucketReport, payload)
            yield put(successAlert('BucketReport created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateBucketReport(error))
        }
    }
}
